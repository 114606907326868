import React from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { purple } from '@mui/material/colors';
import { BASE_URL } from '../../common/api/axios';
import { Software } from './interface';
// import { BASE_URL } from '../../../api/axios';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { convertDateString } from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/utils';

interface SoftwareCardProps {
  title: string;
  imagePath: string;
  description: string;
  handleDownload: (software: Software) => void;
  software: Software;
}

const SoftwareCard: React.FC<SoftwareCardProps> = ({ title, imagePath, description, handleDownload, software }) => {
  return (
    <Card sx={{ maxWidth: 345, height: 250 }}>
      <CardHeader
        sx={{ height: 72 }}
        title={title}
        avatar={
          <Avatar
            sx={{ bgcolor: purple[500] }}
            alt={title[0]}
            src={`${BASE_URL}/${imagePath}`}
          />
        }
      />
      <CardContent sx={{ height: 100, overflow: "hidden" }}>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {
          software?.estimatedRelease ?
            <Stack direction={"row"} pl={1} gap={1} alignItems={"center"}>
              <EventNoteIcon />
              <Typography variant="body2" >
                Estimated Release:
              </Typography>
              {convertDateString(software?.estimatedRelease)}
            </Stack>
            :
            <Button endIcon={<GetAppIcon />} onClick={() =>  handleDownload(software)}>
              Download
            </Button>
        }
        {/* <IconButton onClick={() => handleDownload(software)}>
            <GetAppIcon color='primary' />
        </IconButton> */}
      </CardActions>
    </Card>
  );
};

export default SoftwareCard;
