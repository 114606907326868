export const tabProps = {
  sx: {
    "& .MuiTab-root.MuiButtonBase-root ": {
      // padding: "8px 10px",
    }
  }
}


export const minimalMuiTableProps = {
  // enableColumnActions: false,
  // enableColumnFilters: true,
  // columnFilterDisplayMode: "popover",
  // enablePagination: false,
  enableSorting: false,
  // enableBottomToolbar: false,
  // enableTopToolbar: true,
  // enableToolbarInternalActions: false,
  // muiTableBodyRowProps: { hover: false },
  muiTableProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
    },
  },
  muiTableBodyProps: {
    sx: {
      backgroundColor: "white",
    },

  },
  muiTableHeadCellProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 1,
      backgroundColor: "#F3F2FD",
      // fontSize: "16px",
    },
  },
  muiBottomToolbarProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 0,
      // backgroundColor: "#F8F9FAFF",
      // fontSize: "16px",
    },
  },
  muiTableBodyCellProps: {
    sx: {
      border: "1px solid rgba(224,224,224,0.5)",
      padding: 1,
    },
  },
  muiTableFooterCellProps: {
    sx: {
      // border: "1px solid rgba(224,224,224,0.5)",
      padding: "5px"
    },
  },
  muiTablePaperProps: {
    elevation: 0,
    sx: {
      backgroundColor: "rgba(0,0,0,0)"
    },
  },
  muiTopToolbarProps: {
    sx: {
      backgroundColor: "rgba(0,0,0,0)",
      mb: 1
    },
  }
}
