import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import StockQtyInput from './StockQtyInput';

const date = new Date().toJSON().slice(0, 10);
// MRQ
const name = 'name';
const quantity = 'quantity';
const issueDate = 'issueDate';
const requiredDate = 'requiredDate';
const notes = 'notes';

// MRQ
const mrq_name = 'mrq_name';
// const mrq_quantity = "mrq_quantity"
const mrq_issueDate = 'mrq_issueDate';
const mrq_requiredDate = 'mrq_requiredDate';
const mrq_notes = 'mrq_notes';

// PO
const po_name = 'po_name';
const po_vendor = 'po_vendor';
const po_quantity = 'po_quantity';
const po_issueDate = 'po_issueDate';
const po_requiredDate = 'po_requiredDate';
const po_deliveredDate = 'po_deliveredDate';
const po_notes = 'po_notes';

// MRV
const mrv_name = 'mrv_name';
const mrv_receiver = 'mrv_receiver';
const mrv_quantity = 'mrv_quantity';
const mrv_issueDate = 'mrv_issueDate';
const mrv_approvalStatus = 'mrv_approvalStatus';
const mrv_notes = 'mrv_notes';

// SRV
const srv_name = 'srv_name';
const srv_quantity = 'srv_quantity';
const srv_issueDate = 'srv_issueDate';
const srv_notes = 'srv_notes';

const summedBy = 'description';

const getPOStatus = (status) => {
  if (status === 'received') {
    return (
      <Tooltip title="Complete">
        <CheckCircleIcon color="success" fontSize="medium" />
      </Tooltip>
    );
  } else if (status === '') return <Typography>None</Typography>;
  else if (status === 'delay') {
    return (
      <Tooltip title="Behind Schedule">
        <ErrorOutlineIcon color="warning" fontSize="medium" />
      </Tooltip>
    );
  }
  return (
    <Chip
      icon={<PendingIcon />}
      label="Pending"
      variant="outlined"
      color="primary"
    />
  );
};

export const warehouseColumns = [
  {
    accessorKey: 'material.material_code',
    header: 'Material Code',
  },
  {
    accessorKey: 'material.description',
    header: 'Description',
    size: 400,
  },
  {
    accessorKey: 'material.heat_number',
    header: 'Heat #',
    size: 40,
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    size: 40,
  },
  {
    accessorKey: 'date_in',
    header: 'Date In',
    Cell: ({ cell }) => (
      <div style={{ textAlign: 'left' }}>{cell.getValue()?.split('T')[0]}</div>
    ),
    size: 40,
  },
  {
    accessorKey: 'date_out',
    header: 'Date Out',
    Cell: ({ cell }) => (
      <div style={{ textAlign: 'left' }}>{cell.getValue()?.split('T')[0]}</div>
    ),
    size: 40,
  },
];

export const masterMaterialsColumns = [
  {
    accessorKey: 'materialCode',
    header: 'Material Code',
    size: 40,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 40,
  },
  {
    accessorKey: 'quantity',
    header: 'Qty',
    aggregationFn: 'sum',
    AggregatedCell: ({ cell }) => <div>{cell.getValue()}</div>,
    size: 40,
  },
  {
    accessorKey: 'po.poNumber',
    header: 'PO #',
    size: 40,
    // Cell: ({ cell, row }) => {
    //   const onClick = () => {
    //     // just do navigate()
    //     setHash(window.location.hash, "scm-tab", "Purchase%20Order")
    //     window.location.reload();
    //   }
    //   // const polink = `/supply-chain-management#scm-tab=Purchase%20Order`
    //   return (
    //     // <Link to={`${paths.main_pv}/#main-nav=MRQ`}>
    //     <Button size="small" onClick={onClick}>
    //       {cell.getValue()[0]?.mcsPurchaseOrder?.po_name || ""}
    //     </Button>
    //   )
    // },
  },

  {
    accessorKey: 'cost',
    header: 'PO Cost',
    // aggregationFn: "sum",
    // AggregatedCell: ({ cell }) => <div>{cell.getValue()}</div>,
    size: 40,
  },
  {
    accessorKey: 'mrv.mrvNumber', //to be corrected
    header: 'MRV #',
    size: 50,
    // Cell: ({ cell, row }) => {
    //   const onClick = () => {
    //     // just do navigate()
    //     setHash(window.location.hash, "scm-tab", "Purchase%20Order");
    //     window.location.reload();
    //   };
    //   // const polink = `/supply-chain-management#scm-tab=Purchase%20Order`
    //   return (
    //     // <Link to={`${paths.main_pv}/#main-nav=MRQ`}>
    //     <Button size="small" onClick={onClick}>
    //       {cell.getValue()[0]?.mcsPurchaseOrder?.po_name || ""}
    //     </Button>
    //   );
    // },
  },
  {
    accessorKey: 'vendor.name',
    header: 'Vendor Docs',
    size: 40,
    enableColumnActions: false,
    enableSorting: false,
  },
  {
    accessorKey: 'heatNumber',
    header: 'Heat #',
    // Cell: ({ cell, row }) => {
    //   return <div>{cell.getValue()[0]?.selected_cost || ""}</div>;
    // },
    // aggregationFn: "sum",
    // AggregatedCell: ({ cell }) => (
    //   <div>{cell.getValue()[0]?.selected_cost}</div>
    // ),
    size: 40,
    enableColumnActions: false,
    enableSorting: false,
  },
  {
    accessorKey: 'stockQty',
    header: 'Stock Qty',
    size: 40,
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: true,
    Cell: ({ row }) => {
      return <StockQtyInput row={row} />;
    },
  },
  {
    accessorKey: 'remarks',
    header: 'Remarks',
    enableColumnActions: false,
    enableSorting: false,
  },
];

export const srvDialogColumns = [
  {
    accessorKey: 'id',
    header: 'Id',
    enableHiding: true,
  },
  {
    accessorKey: 'material_code',
    header: 'Code',
  },
  {
    accessorKey: 'description',
    header: 'Description',
  },
  {
    accessorKey: 'qty',
    header: 'Quantity',
    enableColumnActions: false,
    size: 30,
  },
  {
    accessorKey: 'mrv_name',
    header: 'MRV',
    enableColumnActions: false,
    size: 30,
  },
  {
    accessorKey: 'srv_name',
    header: 'SRV',
    enableColumnActions: false,
    size: 30,
  },
];

export const poDialogColumns = [
  {
    accessorKey: 'description',
    header: 'Description',
    Footer: () => <>Total</>,
  },
  {
    accessorKey: 'qty',
    header: 'Quantity',
    size: 20,
    aggregationFn: 'totals',
    // Footer: () => (
    //   <>
    //     Total
    //   </>
    // ),
    Footer: ({ table }) => {
      const totalQty = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return <>{totalQty}</>;
    },
  },
  {
    accessorKey: 'selected.name',
    header: 'Vendor',
    size: 40,
  },
  {
    accessorKey: 'selected.time',
    header: 'Time',
    size: 20,
    Footer: ({ table }) => {
      const totalTime = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.selected.time || 0);
      }, 0);

      return <>{totalTime}</>;
    },
  },
  {
    accessorKey: 'selected.cost',
    header: 'Cost',
    size: 20,
    Footer: ({ table }) => {
      const totalCost = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.selected.cost || 0);
      }, 0);

      return <>{totalCost}</>;
    },
  },
];

export const expandMaterialTable = [
  {
    accessorKey: 'material.material_code',
    header: 'Code',
  },
  {
    accessorKey: 'material.description',
    header: 'Description',
  },
  {
    accessorKey: 'material.qty',
    header: 'Quantity',
    enableColumnActions: false,
    size: 30,
  },
  // {
  //   accessorKey: "material.reference",
  //   header: "Reference",
  //   enableColumnActions: false,

  // },
  // {
  //   accessorKey: "material.material",
  //   header: "Material",
  // },
];

export const srvDialogTable = [
  {
    accessorKey: 'material.material_code',
    header: 'Code',
    enableEditing: false,
  },
  {
    accessorKey: 'material.description',
    header: 'Description',
    enableEditing: false,
  },
  {
    accessorKey: 'material.qty',
    header: 'Quantity',
    enableColumnActions: false,
    size: 30,
    enableEditing: false,
  },
  {
    accessorKey: 'material.heat_number',
    header: 'Heat Number',
  },
];

export const consolidatedBomSummaryColumns = [
  {
    accessorKey: summedBy,
    header: summedBy,
  },
  {
    accessorKey: 'Total Quantity',
    header: 'Total Quantity',
  },
];

export const newMRQFields = [
  {
    label: 'MRQ Number',
    name: 'mrq_name',
  },
  {
    label: 'Required Date',
    name: 'mrq_requiredDate',
    type: 'date',
  },
  {
    label: 'Notes',
    name: 'mrq_notes',
  },
];

export const MRQTableColumns = (selectedBidVendors, handleClickOpen) => [
  // MRQ
  {
    id: 'MRQ',
    header: 'MRQ',
    columns: [
      {
        accessorKey: mrq_name,
        header: 'MRQ No.',
      },
      // {
      //     accessorKey: mrq_quantity,
      //     header: 'Quantity',
      // },
      {
        accessorKey: mrq_issueDate,
        header: 'Issue Date',
      },
      {
        accessorKey: mrq_requiredDate,
        header: 'Required Date',
        Cell: ({ cell }) => (
          <div style={{ textAlign: 'left' }}>
            {cell.getValue()?.split('T')[0]}
          </div>
        ),
      },
      {
        accessorKey: mrq_notes,
        header: 'Notes',
      },
      {
        accessorKey: 'mrq_vendors',
        header: 'Vendors',
        Cell: ({ cell, row }) => (
          <div style={{ display: 'flex' }}>
            <List sx={{ textAlign: 'left', p: 0, m: 0, overflow: 'auto' }}>
              {cell.getValue()?.map((v, i) => {
                return (
                  <ListItem key={v.id} sx={{ p: 0, m: 0 }}>
                    <ListItemText primary={`${v.vendorName}`} />
                  </ListItem>
                );
              })}
            </List>
            <IconButton
              component={'span'}
              onClick={() =>
                handleClickOpen({
                  id: row.original.id,
                  name: row.original.mrq_name,
                  vendors: row.original.mrq_vendors,
                })
              }
            >
              {cell.getValue()?.length ? (
                <EditIcon color="success" />
              ) : (
                <GroupAddIcon color="primary" />
              )}
            </IconButton>
          </div>
        ),
      },
    ],
  },
  // {
  //   id: "PO",
  //   header: "PO",
  //   columns: [
  //     {
  //       accessorKey: "mrq_materials",
  //       header: "PO No.",
  //       Cell: ({ cell }) => (
  //         <div>
  //           {/* Total Quantity: {" "} */}
  //           {cell.getValue()?.map(
  //             (mrq) => mrq?.mcsPurchaseOrder?.po_name ?? ""
  //             // <Typography variant="body3">{mrq.mcsPurchaseOrder.po_name}</Typography>
  //           )}
  //         </div>
  //       ),
  //     },
  //   ],
  // },
  {
    id: 'Project',
    header: 'Project',
    muiTableHeadCellProps: ({}) => ({
      sx: { backgroundColor: '#e9ecf6' },
    }),
    columns: [
      {
        accessorKey: 'equipment.mcsProject.number',
        header: 'Project.',
        muiTableHeadCellProps: ({}) => ({
          sx: { backgroundColor: '#e9ecf6' },
        }),
      },
      {
        accessorKey: 'equipment.name',
        header: 'Equipment',
        size: 30,
        muiTableHeadCellProps: ({}) => ({
          sx: { backgroundColor: '#e9ecf6' },
        }),
      },
    ],
  },
];

export const mrqSummaryColumns = [
  {
    accessorKey: mrq_name,
    header: 'Name',
  },
  // {
  //     accessorKey: mrq_quantity,
  //     header: "Quantity",
  // },
];

export const newPOFormFields = [
  {
    label: 'PO Number',
    name: po_name,
  },
  {
    label: 'Vendor',
    name: po_vendor,
  },
  {
    label: 'Required Date',
    name: po_requiredDate,
    type: 'date',
  },
  {
    label: 'Notes',
    name: po_notes,
  },
];

export const POTableColumns = [
  {
    id: 'PO',
    header: 'PO',
    columns: [
      {
        accessorKey: po_name,
        header: 'PO No.',
      },
      {
        accessorKey: po_vendor,
        header: 'Vendor',
        size: 40,
      },
      {
        accessorKey: po_quantity,
        header: 'Qty',
        size: 20,

        enableColumnActions: false,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 20,

        enableColumnActions: false,
        Cell: ({ cell, row }) => {
          console.log(
            'row',
            row.original.po_requiredDate < date,
            row.original[po_requiredDate],
            date,
          );
          return (
            <div style={{ textAlign: 'left' }}>
              {row.original[po_requiredDate] < date &&
              !row.original[po_deliveredDate]
                ? getPOStatus('delay')
                : row.original[po_deliveredDate]
                  ? getPOStatus('received')
                  : getPOStatus('pending')}
            </div>
          );
        },
      },
      {
        accessorKey: po_issueDate,
        header: 'Issue Date',
        size: 40,

        Cell: ({ cell }) => (
          <div style={{ textAlign: 'left' }}>
            {cell.getValue()?.split('T')[0] || ''}
          </div>
        ),
      },
      {
        accessorKey: po_requiredDate,
        header: 'Required Date',
        size: 40,

        Cell: ({ cell }) => (
          <div style={{ textAlign: 'left' }}>
            {cell.getValue()?.split('T')[0] || ''}
          </div>
        ),
      },
      {
        accessorKey: po_deliveredDate,
        header: 'Delivered Date',
        size: 40,

        Cell: ({ cell }) => (
          <div style={{ textAlign: 'left' }}>
            {cell.getValue()?.split('T')[0] || ''}
          </div>
        ),
      },
      {
        accessorKey: po_notes,
        header: 'Notes',
        enableColumnActions: false,
      },
    ],
  },
  {
    id: 'MRV',
    header: 'MRV',
    columns: [
      {
        accessorKey: 'materialReceivingVouchers',
        header: 'MRV No.',
        Cell: ({ cell }) => (
          <div>
            {/* Total Quantity: {" "} */}
            {cell.getValue()?.map(
              (mrv) => mrv?.mrv_name ?? '',
              // <Typography variant="body3">{mrq.mcsPurchaseOrder.po_name}</Typography>
            )}
          </div>
        ),
      },
    ],
  },
];

export const poSummaryColumns = [
  {
    accessorKey: po_name,
    header: 'Name',
  },
];

export const newMRVFormFields = [
  {
    label: 'MRV Number',
    name: mrv_name,
  },
  {
    label: 'Receiver',
    name: mrv_receiver,
  },
  {
    label: 'Date',
    name: mrv_issueDate,
    type: 'date',
  },
  {
    label: 'Approval Status',
    name: mrv_approvalStatus,
  },
  {
    label: 'Notes',
    name: mrv_notes,
  },
];

export const MRVTableColumns = [
  {
    id: 'MRV',
    header: 'MRV',
    columns: [
      {
        accessorKey: mrv_name,
        header: 'No.',
      },
      {
        accessorKey: mrv_receiver,
        header: 'Receiver',
      },
      {
        accessorKey: mrv_quantity,
        header: 'Quantity',
      },
      {
        accessorKey: mrv_issueDate,
        header: 'Issue Date',
        Cell: ({ cell }) => (
          <div style={{ textAlign: 'left' }}>
            {cell.getValue()?.split('T')[0] || ''}
          </div>
        ),
      },
      {
        accessorKey: mrv_approvalStatus,
        header: 'Approval Status',
      },
      {
        accessorKey: mrv_notes,
        header: 'Notes',
      },
    ],
  },
  {
    id: 'SRV',
    header: 'SRV',
    columns: [
      {
        accessorKey: 'shopReceivingVouchers',
        header: 'SRV No.',
        Cell: ({ cell }) => (
          <div>
            {cell.getValue()?.map(
              (srv) => srv?.srv_name ?? '',
              // <Typography variant="body3">{mrq.mcsPurchaseOrder.po_name}</Typography>
            )}
          </div>
        ),
      },
    ],
  },
];

export const newSRVFormFields = [
  {
    label: 'SRV Number',
    name: srv_name,
  },
  // {
  //     label: "Quantity",
  //     name: srv_quantity,
  // },
  {
    label: 'Date',
    name: srv_issueDate,
    type: 'date',
  },
  {
    label: 'Notes',
    name: srv_notes,
  },
];

export const SRVTableColumns = [
  {
    id: 'SRV',
    header: 'SRV',
    columns: [
      {
        accessorKey: srv_name,
        header: 'No.',
      },
      {
        accessorKey: srv_quantity,
        header: 'Quantity',
      },
      {
        accessorKey: srv_issueDate,
        header: 'Issue Date',
        Cell: ({ cell }) => (
          <div style={{ textAlign: 'left' }}>
            {cell.getValue()?.split('T')[0]}
          </div>
        ),
      },

      {
        accessorKey: srv_notes,
        header: 'Notes',
      },
    ],
  },
];
