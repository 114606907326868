import { Grid, TextField, Typography } from "@mui/material";
import React from "react";

export default function SelectedMaterialsBox({
  currentAction,
  selectedRows,
  heatNumbers,
  setHeatNumbers,
}) {
  React.useEffect(() => {
    const existedHeatNumbersArr = selectedRows.rows.map((row) => {
      const m = row.original;
      return { id: m?.id, heatNumber: m?.heatNumber };
    });
    console.log({ existedHeatNumbersArr });
    setHeatNumbers(existedHeatNumbersArr);
  }, []);

  const onTextChange = (i, id) => (e) => {
    e.preventDefault();

    const newArr = [...heatNumbers];
    newArr[i] = { id, heatNumber: e.target.value };

    setHeatNumbers(newArr);
  };

  return (
    <>
      <Typography variant="h6">Selected Materials are :</Typography>
      {selectedRows?.rows?.map((row, i) => {
        const material = row.original;

        if (material?.description) {
          return (
            <Grid
              container
              sx={{
                backgroundColor: "hsla(250, 86%, 97%, 1)",
                mb: "3px",
                p: 1,
                borderRadius: "5px",
              }}
              key={material?.description}
            >
              <Grid item xs={currentAction === "MRV" ? 8 : 12}>
                - {material?.description}
              </Grid>
              {currentAction === "MRV" && (
                <Grid item xs={4}>
                  <TextField
                    id="heatNumber"
                    label="Heat Number"
                    name="heatNumber"
                    value={heatNumbers[i]?.heatNumber || ""}
                    onChange={onTextChange(i, material.id[0])}
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                  />
                </Grid>
              )}
            </Grid>
          );
        }
        return material?.tagNumber?.map((tagNumber) => {
          return (
            <Grid
              container
              sx={{
                backgroundColor: "hsla(250, 86%, 97%, 1)",
                mb: "3px",
                p: 1,
                borderRadius: "5px",
              }}
              key={tagNumber}
            >
              <Grid item xs={8}>
                - {tagNumber} / {material.materialCode}
              </Grid>
            </Grid>
          );
        });
      })}
    </>
  );
}
