import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ProjectTable from './components/ProjectTable';
import User from '../UsersPage/interface/userInterface';
import { getUsers } from '../UsersPage/api';
import Viewer from './Viewer';
import { MRT_Row } from 'material-react-table';
import Project from './interfaces/projectInterface';

const ProjectsPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [viewerModalState, setViewerModalState] = useState(false);
  const [clickedProject, setClickedProject] = useState<string>('');

  useEffect(() => {
    const fetchUsers = async () => {
      await getUsers().then((res) => setUsers(res));
    };

    fetchUsers();
  }, []);
  const handleOpen = (row: MRT_Row<Project>) => {
    console.log('row', row.original.name);
    setClickedProject(row.original.name);
    setViewerModalState(true);
  };
  return (
    <Box id="projects-page" sx={{ padding: '2rem' }}>
      <Stack
        sx={{ paddingBottom: '2rem' }}
        direction={'row'}
        justifyContent="space-between"
        height={'3rem'}
        alignItems="center"
      >
        <h2>Projects</h2>
        {/* <Button
          variant="contained"
          sx={{ borderRadius: '0rem', width: '10rem', height: '2rem' }}
        >
          + Add Project
        </Button> */}
      </Stack>
      <ProjectTable users={users} openViewerModal={handleOpen} />
      <Viewer
        open={viewerModalState}
        handleClose={(event) => {
          setViewerModalState(false);
        }}
        projectName={clickedProject}
      />
    </Box>
  );
};

export default ProjectsPage;
