import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import LabelledBox from '../../../../../common/components/LabelledBox';
import { BootstrapInput } from '../../../../../common/components/BootstrapInput';
import MRQ from './MrqInterface';
import { MCSContext } from './MCS.context';

interface MrqSelectorProps {
    mrqList: MRQ[];
    selectedMrq: string;
    selectedRevision: string;
    revisions: { id: string; name: string }[];
    onMrqChange: (event: SelectChangeEvent<string>) => void;
    onRevisionChange: (event: SelectChangeEvent<string>) => void;
}

const MrqSelector = ({
    mrqList,
    selectedMrq,
    selectedRevision,
    revisions,
    onMrqChange,
    onRevisionChange
}: MrqSelectorProps) => {

    const { handleMrqChange, mrqSelection } = React.useContext(MCSContext)
    return (

        <LabelledBox label="MRQ Manager">
            <FormControl variant="standard" size="small" sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}>
                <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>MRQ Number</InputLabel>
                <Select
                    labelId="mrq-label"
                    value={mrqSelection.selectedMrq}
                    onChange={handleMrqChange}
                    label="Mrq"
                    size='small'
                    input={<BootstrapInput />}
                >
                    {mrqList.map(mrq => (
                        <MenuItem key={mrq.id} value={mrq.id}>{mrq.mrqNumber}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl variant="standard" size="small" sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}>
                <InputLabel id="revision-label" shrink sx={{ fontSize: "1.1rem" }}>Revision</InputLabel>
                <Select
                    labelId="revision-label"
                    value={selectedRevision}
                    onChange={onRevisionChange}
                    disabled={!selectedMrq}
                    label="Revision"
                    input={<BootstrapInput />}
                >
                    {revisions.map(rev => (
                        <MenuItem key={rev.id} value={rev.id}>{rev.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </LabelledBox>

        // <LabelledBox label="MRQ Manager">
        //     <FormControl variant="standard" size="small" sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}>
        //         <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>MRQ Number</InputLabel>
        //         <Select
        //             labelId="mrq-label"
        //             id="mrq"
        //             value={selectedMrqID}
        //             onChange={handleChange}
        //             label="MRQ"
        //             size='small'
        //             input={<BootstrapInput />}
        //         >
        //             <MenuItem value="">
        //                 <em>None</em>
        //             </MenuItem>
        //             {mrqList.map((mrq: MRQ) => (
        //                 <MenuItem key={mrq.id} value={mrq.id}>{mrq.mrqNumber}</MenuItem>
        //             ))}
        //         </Select>
        //     </FormControl>
        //     <FormControl variant="standard" sx={{ m: 1, mt: 0, minWidth: 120 }} size="small">
        //         <InputLabel id="revision-label" shrink sx={{ fontSize: "1.1rem" }}>Revision</InputLabel>
        //         <Select
        //             labelId="revision-label"
        //             id="revision"
        //             value={selectedRevision}
        //             // onChange={handleRevisionChange}
        //             onChange={handleChange}
        //             label="Revision"
        //             input={<BootstrapInput />}
        //             disabled={!selectedMrq} // Disable if no mrqNumber selected
        //         >
        //             <MenuItem value="">
        //                 <em>None</em>
        //             </MenuItem>
        //             {revisions?.map(rev => (
        //                 <MenuItem key={rev.id} value={rev.id}>{rev.name}</MenuItem>
        //             ))}
        //         </Select>
        //     </FormControl>
        // </LabelledBox>
    )



    // const [selectedMrq, setSelectedMrq] = React.useState('');

    // const handleChange = (event: SelectChangeEvent) => {
    //     setSelectedMrq(event.target.value);
    // };

    // return (
    // <Box sx={{ "backgroundColor": "#f3f4f6", "borderRadius": "10px", display: "flex", flexDirection: "row" }}>

    // {/* <Typography>Select an MRQ</Typography> */ }

    // <LabelledBox label="MRQ Manager" >
    //     <FormControl variant="standard" size="small" sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}>
    //         <InputLabel id="standard-label" shrink sx={{ fontSize: "1.1rem" }}>Mrq Number</InputLabel>
    //         <Select
    //             labelId="standard-label"
    //             id="standard"
    //             value={selectedMrq}
    //             onChange={handleChange}
    //             label="Mrq"
    //             size='small'
    //             input={<BootstrapInput />}
    //         >
    //             <MenuItem value="">
    //                 <em>None</em>
    //             </MenuItem>
    //             {
    //                 mrqList.map((mrq: MRQ) => (
    //                     <MenuItem value={mrq.id}>{mrq.mrqNumber}</MenuItem>
    //                 ))
    //             }
    //         </Select>
    //     </FormControl>
    //     <FormControl variant="standard" sx={{ m: 1, mt: 0, minWidth: 120 }} size="small">
    //         <InputLabel id="filled-label" shrink sx={{ fontSize: "1.1rem" }}>Revision</InputLabel>
    //         <Select
    //             labelId="standard-label"
    //             id="standard"
    //             value={selectedMrq}
    //             onChange={handleChange}
    //             label="Revision"
    //             input={<BootstrapInput />}
    //         >
    //             <MenuItem value="">
    //                 <em>None</em>
    //             </MenuItem>
    //             <MenuItem value={10}>Ten</MenuItem>
    //             <MenuItem value={20}>Twenty</MenuItem>
    //             <MenuItem value={30}>Thirty</MenuItem>
    //         </Select>
    //     </FormControl>
    // </LabelledBox >

    // </Box>
    // )
}

export default MrqSelector