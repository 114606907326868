import { mainPaths } from '../../../../routing/paths';
import AppsIcon from '@mui/icons-material/Apps';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { paths as projectPaths } from '../../../../pages/ProjectsPage/projectsRoutes';
import { paths as taskPaths } from '../../../../pages/PunchlistPage/taskRoutes';
export const navItems = [
  {
    label: 'Projects',
    link: projectPaths.myProjects,
  },
  // {
  //     label: "RACI",
  //     link: mainPaths.raci,
  // },
  // {
  //     label: "Tasks",
  //     link: taskPaths.mytasks,
  // },
  {
    label: 'Timesheets',
    link: mainPaths.timesheets,
  },
  // {
  //     label: "Material database",
  //     link: mainPaths.materialDb,
  // },
  // {
  //     label: "Material Requisitions",
  //     link: mainPaths.mrqs,
  // },
  {
    label: 'Software',
    link: mainPaths.software,
  },
];
