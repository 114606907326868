import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { getCols } from "./getCols";
import { MCSContext } from "../../MCS.context";

export default function BidTableHeader() {
  const { mrqData, selectedBidVendors } = React.useContext(MCSContext);
  return (
    <TableHead>
      <TableRow>
        {getCols(mrqData, selectedBidVendors).map((col) => (
          <TableCell
            key={col?.id}
            align={col?.align}
            style={{
              minWidth: col?.minWidth,
              fontWeight: "bold",
              background: "hsla(250, 86%, 97%, 100)",
            }}
          >
            <div style={{ fontSize: 15, paddingBottom: 7 }}>{col?.label}</div>
            {col?.isVendor ? (
              <div>
                {col?.id === "selected" && (
                  <div
                    style={{
                      display: "inline-block",
                      width: "33%",
                      fontWeight: 400,
                      borderRight: "1px solid #333",
                    }}
                  >
                    Name
                    <br />
                  <span style={{ fontSize: "0.8em", fontWeight: "normal" }}> -</span>
                  </div>
                )}
                <div
                  style={{
                    display: "inline-block",
                    width: col.id === "selected" ? "33%" : "40%",
                    padding: 2,
                    borderRight: "1px solid #333",
                    height: "100%",
                    fontWeight: 400,
                  }}
                >
                  Time
                  <br />
                  <span style={{ fontSize: "0.8em", fontWeight: "normal" }}>(days)</span>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: col?.id === "selected" ? "33%" : "40%",
                    padding: 2,

                    fontWeight: 400,
                  }}
                >
                  Cost/Unit{" "}
                  <br />
                  <span style={{ fontSize: "0.8em", fontWeight: "normal" }}>(USD)</span>
                </div>
                {col?.id !== "selected" && (
                  <div
                    style={{
                      display: "inline-block",
                      width: "20%",
                      fontWeight: 400,
                      borderRight: "1px solid #333",
                    }}
                  ></div>
                )}
              </div>
            ) : (
              ""
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
