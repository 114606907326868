import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API;

export default axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

export const AUTODESK_BASE_URL = process.env.REACT_APP_AUTODESK_API;

export const autodesk_axios = axios.create({
  withCredentials: true,
  baseURL: AUTODESK_BASE_URL,
});
