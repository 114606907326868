import axios from "../../../../../common/api/axios";
import MRQ from "./MrqInterface";

export const getMrqs = async (): Promise<MRQ[]> => {
    const response = await axios.get(`/mrq/manager`);
    console.log({ response });
    return response.data;
};

export const getMrqData = async (id: string): Promise<MRQ> => {
    const response = await axios.get(`/mrq/${id}`);
    console.log({ response });
    return response.data;
};

export const getMrqPos = async (id: string) => {
    const response = await axios.get(`po/mrq/${id}`);
    console.log({ response });
    return response.data;
};

export const addMaterialsTo = async ({ to, body }: { to: string, body: any }) => {
    const { data } = await axios.post(to, body);
    return data;
};

export const getAllVouchers = async (to: string) => {
    const response = await axios.get(`/${to}`);
    // console.log({ response });
    return response.data;
};

