import React, { useEffect, useState } from 'react'
import { EditableTable } from '../../common/components/table/EditableTable'
import { createTask, deleteTask, getAllTasks, getTasks, updateTask } from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/api'
import { initialDialogState, taskListColumns } from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/utils'
import CommentsDialog from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/components/CommentsDialog.tsx'
import TimesheetDialog from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/components/TimesheetDialog.tsx'
import { useParams } from 'react-router-dom'
import Task from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/taskInterface'
import Project from '../ProjectsPage/interfaces/projectInterface'
import { columns } from './columns'
import { Box, Stack } from '@mui/material'
import HeadingComponent from '../../common/components/HeadingComponent'
import User from '../UsersPage/interface/userInterface'
import { getUsers } from '../UsersPage/api'


const initialData: Task = {
    id: "",
    task: "",
    priority: "",
    startDate: "",
    endDate: "",
    status: "",
    hours: 0,
    progress: 0,
    users: [],

}


// interface PunchlistProps {
//     project: Project | undefined
// }

const TasksPage = () => {

    const currentUser = "currentUser"


    // console.log("project users", project?.users)
    const { projectId } = useParams()
    const [dialogState, setDialogState] = React.useState(initialDialogState)
    const [selectedTask, setSelectedTask] = React.useState()

    const [users, setUsers] = useState<User[]>([])

    useEffect(() => {
        const fetchUsers = async () => {
            await getUsers()
                .then(res => setUsers(res))
        };

        fetchUsers()
    }, [])

    console.log(users)
    
    const handleButtonClick = (type: string, row: any) => {
        setSelectedTask(row.original)

        if (type === 'comments') {
            setDialogState(prev => ({ ...prev, comments: true }))
        }
        else if (type === 'attachment') {
            setDialogState(prev => ({ ...prev, attachment: true }))
        }
        else if (type === 'timesheet') {
            setDialogState(prev => ({ ...prev, timesheet: true }))
        }
    }

    const handleDialogClose = () => {
        setDialogState(initialDialogState)
    }

    const additionalProps = {
        enableColumnActions: true,
        enableGrouping: true,
        enableColumnDragging: false
    }


    return (
        <Box padding={"2rem"}>
            <HeadingComponent heading='Tasks' />
            <EditableTable<Task>
                name="Task"
                displayMode="row"
                columns={columns({
                    members: users,
                    handleButtonClick: handleButtonClick,
                    currentUser,
                })}
                enableEditing={true}
                enableCreate={true}
                initialData={initialData}
                api={{
                    getItems: getAllTasks,
                    createItem: createTask,
                    updateItem: updateTask,
                    deleteItem: deleteTask,
                }}
                parentId={projectId!}
                additionalProps={additionalProps}
            />
            {
                selectedTask ?
                    <>
                        <CommentsDialog open={dialogState.comments} handleClose={handleDialogClose} task={selectedTask} />
                        <TimesheetDialog open={dialogState.timesheet} handleClose={handleDialogClose} task={selectedTask} />
                    </>
                    :
                    <>
                    </>
            }
        </Box>
    )
}

export default TasksPage