import FileCopyOutlinedIcon from "@mui/icons-material/FileCopy";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutline";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToReg";
import PendingOutlinedIcon from "@mui/icons-material/Pending";

export const cards = (documents) => [
  {
    title: "Documents",
    color: "#CAC0E2FF",
    icon: <FileCopyOutlinedIcon sx={{ color: "#553F89FF" }} />,
    valueLabel: documents?.length,
  },
  {
    title: "In Progress",
    color: "#379AE6FF",
    icon: <DriveFileRenameOutlineOutlinedIcon sx={{ color: "#FFFFFF" }} />,
    valueLabel: documents?.length,
  },
  {
    title: "Approved",
    color: "#1DD75BFF",
    icon: <HowToRegOutlinedIcon sx={{ color: "#0A4D20FF" }} />,
    valueLabel: "0",
  },
  {
    title: "Waiting for Approval",
    color: "#EFB034FF",
    icon: <PendingOutlinedIcon sx={{ color: "#5D4108FF" }} />,
    valueLabel: "0",
  },
];
