// import project from './interfaces/projectInterface';
// import axois from '../../common/api/axois';
// import axios from 'axios';

import { useParams } from "react-router-dom";
import axios from "../../../../../common/api/axios";
import Task from "./taskInterface";


export const getAllTasks = async (): Promise<Task[]> => {
  const response = await axios.get(`/task`);
  return response.data;
};

export const getTasks = async (projectId: string | undefined): Promise<Task[]> => {
  const response = await axios.get(`/task?projectId=${projectId}`);
  return response.data;
};

export const createTask = async (parentId: string, task: Task): Promise<void> => {
  console.log(task)
  // TODO: Add parentId to task details here.
  await axios.post('/task', task);
};

export const updateTask = async (task: Task): Promise<void> => {
  await axios.put(`/task/${task.id}`, task);
};

export const deleteTask = async (taskId: string): Promise<void> => {
  await axios.delete(`/task/${taskId}`);
};
