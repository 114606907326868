import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTheme } from '@emotion/react';
import { MCSContext } from '../../MCS.context';
import { VendorSelectionBtns } from './VendorSelectionBtns';
import { addMrqVendors, getCompanyVendors } from '../api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const VendorSelector = ({ isMultiple }) => {
  const theme = useTheme();
  const { selectedBidVendors, setSelectedVendors, setMrqData, mrqSelection } =
    React.useContext(MCSContext);

  const [companyVendors, setCompanyVendors] = React.useState([]);

  React.useEffect(() => {
    const fetchVendors = async () => {
      await getCompanyVendors().then((res) => setCompanyVendors(res));
    };

    fetchVendors();
  }, []);

  const handleSelectVendor = (event) => {
    const value = event?.target?.value;
    setSelectedVendors(typeof value === 'string' ? [value] : value);
    console.log({ vendorVC: selectedBidVendors[0] });
    !isMultiple && handleSelectVendorSubmit([value]);
  };

  const handleSelectVendorSubmit = async (value) => {
    const submittedVendors = isMultiple ? selectedBidVendors : value;

    const selectedVendorsIds = submittedVendors.map((name) => {
      return companyVendors.filter((v) => v.name === name)[0].id;
    });

    const data = await addMrqVendors({
      mrqId: mrqSelection?.selectedRevision || mrqSelection?.selectedMrq,
      selectedVendorsIds,
    });
    if (data?.error) {
      alert('Error adding MRQ vendors');
    }
    if (data?.vendors) {
      setMrqData(data);
      console.log('vvendors', data);
    }
  };

  return (
    <Box display={'flex'} gap={2} mt={1.5}>
      <>
        <FormControl sx={{ width: 300 }} size="small">
          <InputLabel id="demo-multiple-chip-label">
            Select Bid Vendors
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple={isMultiple ? true : false}
            value={selectedBidVendors ? selectedBidVendors : []}
            onChange={handleSelectVendor}
            input={
              <OutlinedInput
                id="select-multiple-chip"
                label="Select Bid Vendors"
              />
            }
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {companyVendors.map((v) => (
              <MenuItem
                key={v.name}
                value={v.name}
                style={getStyles(
                  v.name,
                  selectedBidVendors ? selectedBidVendors : [],
                  theme,
                )}
              >
                <ListItemText>{v.name}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {v.email}
                </Typography>
              </MenuItem>
            ))}
            {isMultiple && (
              <VendorSelectionBtns
                handleSelectVendorSubmit={handleSelectVendorSubmit}
              />
            )}
          </Select>
        </FormControl>
      </>
    </Box>
  );
};

export default VendorSelector;

function getStyles(name, selected, theme) {
  return {
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
