import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Navigate, Outlet } from "react-router-dom";
import {  mainPaths } from "./paths";

export const AuthProtected = () => {


    return (
        // <AuthContextProvider>
        <div>
            <AuthenticatedTemplate>
                {/* Content to render if the user is signed in */}
                <Outlet />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {/* Content to render if the user is not signed in */}
                <Navigate to={mainPaths.login} />
            </UnauthenticatedTemplate>
        </div>
        // </AuthContextProvider>
    )
}