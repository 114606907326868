import { SxProps, Tab, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface LinkTabProps {
    label: string;
    to: string;
    base: string;
    // additionalProps: SxProps<Theme >;
}

export function LinkTab(props: LinkTabProps) {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(`${props.base}/${props.to}`);
    };

    return (
        <Tab
            label={props.label}
            component="a"
            onClick={handleClick}
        // {...props.additionalProps}
        // sx={{ alignItems: "flex-start", borderBottom: "1px solid #edebeb" }}

        />
    );
}