import {
    Badge,
    Box,
    Button,
    Chip,
    IconButton,
    MenuItem,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { MRT_ColumnDef } from "material-react-table";
import ProjectUser from "../ProjectsPage/interfaces/projectUserInterface";
import Task from "../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/taskInterface";
import User from "../UsersPage/interface/userInterface";
// import { CircularProgressWithLabel } from "../../../../../components/widgets/CircularProgressWithLabel";

interface TaskListColumnsProps {
    members?: User[] | undefined;
    handleButtonClick: (type: string, row: any) => void; // Assuming you have a function signature here
    currentUser: string;
}

const getOrdinalSuffix = (number: number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = number % 100;
    return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

const convertDateString = (dateString: string) => {
    const dateObject = new Date(dateString);
    // Format the date
    // const formattedDate = `${getOrdinalSuffix(dateObject.getDate())} ${dateObject.toLocaleString('en-US', { month: 'short' })} ${dateObject.getFullYear()}`;
    const formattedDate = `${dateObject.getDate()} ${dateObject.toLocaleString('en-US', { month: 'short' })} '${dateObject.getFullYear().toString().slice(2,4)}`;
    return (
        <p>{formattedDate}</p>
    );
};

const statusColorMap: Record<string, string> = {
    'Not Started': 'default',
    'Progress': 'primary',
    'Hold': 'warning',
    'Completed': 'success',
    'Delayed': 'error',
};

export const initialDialogState = {
    comments: false,
    attachment: false,
    timesheet: false
}

export const columns = ({
    members,
    handleButtonClick,
    currentUser,
}: TaskListColumnsProps): MRT_ColumnDef<Task>[] => [
        {
            accessorKey: "id",
            header: `ID`,
            enableEditing: false,
            Cell: ({ cell, row }: any) => (
                <>
                </>
            )
        },
        {
            accessorKey: "task",
            header: `Task`,
            enableEditing: (row: any) => {
                if (row.original.task === "") return true;
                else if (currentUser)
                    return (row?.original?.task_members?.includes(currentUser))
                else return false;
            },
            size: 320,
        },
        {
            accessorKey: "project.name",
            header: `Project`,
            enableEditing: (row: any) => {
                if (row.original.task === "") return true;
                else if (currentUser)
                    return (row?.original?.task_members?.includes(currentUser))
                else return false;
            },
            size: 100,
        },
        {
            accessorKey: "users",
            header: `Responsible`,
            size: 100,
            Cell: ({ cell, row }: any) => {
                return (

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {
                            cell.getValue() ?
                                cell.getValue().map((user: string, index: number) => (
                                    // <Chip key={index} label={user.name} />
                                    <Chip key={index} label={members?.find(member => member.id === user)?.name} />
                                ))
                                :
                                ""
                        }
                    </Box>
                );
            },
            // muiEditTextFieldProps: ({ value, onChange }: any) => ({
            //     children: (project_members || [])?.map((user: ProjectUser, index) => (
            //         <MenuItem key={user.userId} value={user.userId}>
            //             {user.name}
            //         </MenuItem>
            //     )),
            //     select: true,
            //     SelectProps: {
            //         multiple: true,
            //         renderValue: (selected) => {
            //             console.log({ selected })
            //             return (
            //                 <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            //                     {(selected as string[]).map((value, index) => (
            //                         <Chip key={index} label={project_members?.find(member => member.userId === value)?.name} />
            //                     ))}
            //                 </Box>
            //             )
            //         }
            //     }
            // }),
        },
        {
            accessorKey: "priority",
            header: 'Priority',
            size: 90,
            muiEditTextFieldProps: () => ({
                children: ["High", "Medium", "Low"].map((func) => (
                    <MenuItem key={func} value={func}>
                        {func}
                    </MenuItem>
                )),
                select: true,
            }),
        },
        {
            accessorKey: "startDate",
            header: `Start`,
            size: 80,
            enableColumnFilter: false,
            Cell: ({ cell }: any) => (
                <>
                    {
                        cell.getValue() ?
                            convertDateString(cell.getValue())
                            : ""
                    }
                </>
            ),
            muiEditTextFieldProps: ({ cell }: any) => ({
                type: "date",
            }),
        },
        {
            accessorKey: "endDate",
            header: `Due`,
            size: 80,
            enableColumnFilter: false,
            Cell: ({ cell }: any) => (
                <>
                    {
                        cell.getValue() ?
                            convertDateString(cell.getValue())
                            : ""
                    }
                </>
            ),
            muiEditTextFieldProps: ({ cell }: any) => ({
                type: "date",
            }),
        },
        {
            accessorKey: "hours",
            header: 'Hours',
            enableEditing: false,
            enableColumnFilter: false,
            size: 80,
        },
        // {
        //     accessorKey: "progress",
        //     header: 'Progress',
        //     enableEditing: false,
        //     enableColumnFilter: false,
        //     size: 80,

        //     Cell: ({ cell, row }: any) => (
        //         cell.getValue()
        //         // <CircularProgressWithLabel value={cell.getValue()} />
        //     ),
        // },
        {
            accessorKey: "status",
            header: `Status`,
            size: 90,
            muiEditTextFieldProps: () => ({
                children: ["Not Started", "Progress", "Hold", "Completed", "Delayed"].map((func) => (
                    <MenuItem key={func} value={func}>
                        {func}
                    </MenuItem>
                )),
                select: true,
            }),
            Cell: ({ cell }: any) => (
                <>
                    {
                        cell.getValue() ?
                            <Chip
                                label={cell.getValue()}
                                color={statusColorMap[cell.getValue()] as 'default' | 'primary' | 'warning' | 'success' | 'error' | 'secondary' | 'info' || 'default'}
                            /> :
                            <></>
                    }
                </>
            ),
        },
        {
            accessorKey: "comments",
            header: `Comments`,
            enableEditing: false,
            enableSorting: false,
            enableColumnFilter: false,
            size: 50,
            Header: () => <ChatIcon />,
            Cell: ({ row }: any) => (
                <IconButton
                    onClick={() => handleButtonClick('comments', row)}
                // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
                >
                    {/* <Badge badgeContent={2} color="primary" > */}
                    <ChatIcon />
                    {/* </Badge> */}
                </IconButton>
            ),
        },
        // {
        //     accessorKey: "attachment",
        //     header: `Attachment`,
        //     enableEditing: false,
        //     enableSorting: false,
        //     enableColumnFilter: false,
        //     size: 20,
        //     Header: () => <AttachFileIcon />,
        //     Cell: ({ row }: any) => (
        //         <IconButton
        //             onClick={() => handleButtonClick('attachment', row)}
        //         // disabled={
        //         // (project_members && project_members.includes(currentUser)) ||
        //         //     (projectLeads && projectLeads.includes(currentUser))
        //         //     ? false
        //         //     : true
        //         // }
        //         >
        //             <AttachFileIcon />
        //         </IconButton>
        //     ),
        // },
        {
            accessorKey: "timesheet",
            header: `Timesheet`,
            enableEditing: false,
            enableSorting: false,
            enableColumnFilter: false,
            size: 20,
            Header: () => <PostAddIcon />,
            Cell: ({ row }: any) => (
                <IconButton
                    onClick={() => handleButtonClick('timesheet', row)}
                // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
                >
                    <PostAddIcon />
                </IconButton>
            ),
        },
    ];