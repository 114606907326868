import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, MenuItem } from '@mui/material';
// import RowEditableTable from "../../../../../../../components/Tables/RowEditableTable";
// import { secondAxios } from "../../../../../../../api/axios";
import {
  createDocumentList,
  getProjectDocumentList,
  updateDocumentList,
  deleteDocumentList,
} from './docListAPI';
// import EditableTable from '../../../../../../../components/Tables/EditableTable';
import { EditableTable } from '../../../../../../../common/components/table/EditableTable';
import { useDCS } from '../DCSContext';
// Sample data for demonstration
// export const sampleDocuments = [
//   {
//     number: 1,
//     department: "Piping",
//     docNumber: "DOC001",
//     title: "Employee Handbook",
//     responsible: "Ahmed Osama",
//   },
//   {
//     number: 2,
//     department: "Structure",
//     docNumber: "DOC002",
//     title: "Annual Report",
//     responsible: "Omar Mokhtar",
//   },
//   {
//     number: 3,
//     department: "Structure",
//     docNumber: "DOC003",
//     title: "Security Policy",
//     responsible: "Ahmed Samy",
//   },
// ];
const departments = [
  'Piping',
  'Structure',
  'Vendor',
  'Tank',
  'Pressure Vessel',
];

export const DocLsitInitialData = {
  number: '',
  department: '',
  title: '',
  responsible: '',
};

export const DocListColumns = (allUsers, type) => [
  {
    accessorKey: 'docNumber',
    header: `#`,
    size: 20,
  },
  {
    accessorKey: 'department',
    header: `Department`,
    enableEditing: true,
    muiEditTextFieldProps: () => ({
      children: departments.map((dep, index) => (
        <MenuItem key={index} value={dep}>
          {dep}
        </MenuItem>
      )),
      select: true,
    }),
    size: 30,
  },
  {
    accessorKey: 'title',
    header: 'Title',
    size: 40,
  },
  {
    accessorKey: 'responsible',
    header: 'Responsible',
    size: 90,
    enableSorting: false,
    muiEditTextFieldProps: () => ({
      children: allUsers.map((user) => (
        <MenuItem key={user.email} value={user.email}>
          {user.name}
        </MenuItem>
      )),
      select: true,
    }),
    Cell: ({ cell, row }) => {
      return cell.getValue();
    },
  },
];

const DocumentList = ({ open, handleClose, documents }) => {
  // const [allUsers, setAllUsers] = useState([]);
  const { allUsers, documentList, type, equipId } = useDCS();

  useEffect(() => {
    // const getAllUsers = async () => {
    //   const users = (await secondAxios.get("/user")).data;
    //   console.log(users);
    //   setAllUsers(users);
    // };
    // getAllUsers();
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          //   border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Document List
        </Typography>
        {/* <RowEditableTable
          columns={DocListColumns(allUsers)}
          data={documentList}
          handleCreateRow={handleCreateRow}
        /> */}
        <EditableTable
          name="Doc List"
          displayMode="row"
          columns={DocListColumns(allUsers)}
          enableEditing={true}
          enableCreate={true}
          initialData={DocLsitInitialData}
          api={{
            getItems: getProjectDocumentList.bind(null, type),
            createItem: createDocumentList.bind(null, type),
            updateItem: updateDocumentList,
            deleteItem: deleteDocumentList,
          }}
          parentId={equipId}
        />
      </Box>
    </Modal>
  );
};

export default DocumentList;
