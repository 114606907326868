import React from 'react'
import MRQTab from '../SingleProjectPage/tabs/MRQTab'
import HeadingComponent from '../../../common/components/HeadingComponent'
import { Box } from '@mui/material'

const ProjectMrqPage = () => {
    return (
        <Box padding={"2rem"}>
            <HeadingComponent heading='MRQ' />
            <MRQTab />
        </Box>
    )
}

export default ProjectMrqPage