import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import TabPanel from '../widgets/TabPanel';
import { useState } from 'react';
import { useEffect } from 'react';
import TabPanelFormat from '../widgets/TabPanelFormat';
import VerticalTabPanelFormat from '../widgets/verticalTabPanelFormat';
import { fullPageBoxStyle } from '../../styles/muiProps';
import { getHashdata, setHash } from './Utils/hashFunctions';

// function samePageLinkNavigation(event) {
//     if (
//         event.defaultPrevented ||
//         event.button !== 0 || // ignore everything but left-click
//         event.metaKey ||
//         event.ctrlKey ||
//         event.altKey ||
//         event.shiftKey
//     ) {
//         return false;
//     }
//     return true;
// }

// function LinkTab(props) {
//     return (
//         <Tab
//             component={Link}
//             onClick={(event) => {
//                 // Routing libraries handle this, you can remove the onClick handle when using them.
//                 // if (samePageLinkNavigation(event)) {
//                 //   event.preventDefault();
//                 // }
//             }}
//             {...props}
//         />
//     );
// }

// const getHashdata = (fullHash, hashname) => {
//     const hashData = fullHash.substring(1) // Remove the "#" character
//         .split('&') // Split the hash fragment into key-value pairs
//         .reduce((acc, pair) => {
//             const [key, value] = pair.split('=');
//             acc[key] = value;
//             return acc;
//         }, {});
//     return hashData[hashname];
// }

// // const tabs = [
// //     // { label: "Page One",panel: (</>), to: "drafts" },
// //     { label: "Page Two", to: "trash" },
// //     { label: "Page Three", to: "spam" }
// // ]
// const setHash = (fullHash, hashname, hashvalue) => {
//     // #main-nav=Data&data-tab=Design&design-tab=vortex
//     const hashPairs = fullHash.substring(1).split('&');
//     let updatedHash = ""

//     for (let i = 0; i < hashPairs.length; i++) {
//         const element = hashPairs[i];
//         const [key, value] = element.split('=');
//         console.log(element);

//         if (key === hashname) {
//             updatedHash += hashname + "=" + hashvalue
//             break
//         }
//         updatedHash += element + "&"
//     }
//     console.log({ updatedHash })
//     window.location.hash = updatedHash
// }

export default function NavTabs(props) {
  const { navLabel, tabs, tabProps, vertical, bgcolor } = props;
  const [currentTab, setCurrentTab] = useState(0);
  // const [search, setSearch] = useSearchParams();

  const handleTitle = (event) => {};

  const location = useLocation();
  const fullHash = window.location.hash;
  useEffect(() => {
    const hashValue = getHashdata(fullHash, navLabel);
    const tabIndex = tabs.findIndex(
      (tab) => tab.label === decodeURIComponent(hashValue),
    );

    // console.log({ tabIndex });
    // if hash value for tabs is present, set it as currentTab
    if (tabIndex !== -1) {
      setCurrentTab(tabIndex);
    }
    // if its not present, but there is some existing hash value, add this to the hash value
    else if (fullHash)
      window.location.hash = fullHash + '&' + navLabel + '=' + tabs[0].label;
    // if not either, start a new hash
    else window.location.hash = navLabel + '=' + tabs[0].label;
  }, []);

  const handleChange = (event, newValue) => {
    console.log('CHNGE');
    setCurrentTab(newValue);
    // Set a hash in the URL
    setHash(fullHash, navLabel, tabs[newValue].label);
    // window.location.hash = tabs[newValue].label;

    // search.set(navLabel, tabs[newValue].label);
    // setSearch(search);
    // setSearch((prev) => ({ ...prev, [navLabel]: tabs[newValue].label }))
  };

  return (
    // <Box sx={{ width: '100%' }}>
    <Box
      {...(vertical ? fullPageBoxStyle : { width: '100%', overflow: 'auto' })}
      bgcolor={bgcolor ?? ''}
    >
      <Tabs
        value={currentTab}
        onChange={handleChange}
        orientation={vertical ? 'vertical' : 'horizontal'}
        {...(tabProps ? tabProps : {})}
      >
        {tabs.map((tab, i) => {
          return <Tab label={tab.label} value={i} />;
        })}
      </Tabs>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'start',
        }}
      >
        {vertical ? (
          <VerticalTabPanelFormat {...tabs[currentTab].panelProps}>
            {tabs[currentTab].panel}
          </VerticalTabPanelFormat>
        ) : (
          <TabPanelFormat {...tabs[currentTab].panelProps}>
            {tabs[currentTab].panel}
          </TabPanelFormat>
        )}
      </Box>
    </Box>
  );
}
