import axios from "../../../../../../../common/api/axios";
import Comment from "./interface";

export const getComments = async (): Promise<Comment[]> => {
    const response = await axios.get('/comments');
    return response.data;
  };
  
  export const createComment = async (comment: Comment): Promise<void> => {
    await axios.post('/comments', comment);
  };
  
  export const updateComment = async (comment: Comment): Promise<void> => {
    await axios.put(`/comments/${comment.id}`, comment);
  };
  
  export const deleteComment = async (commentId: string): Promise<void> => {
    await axios.delete(`/comments/${commentId}`);
  };
  