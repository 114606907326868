// ViewerComponent.js
import React, { useEffect, useState } from 'react';
import { initViewer, loadModel } from './viewer.js';
import { Box, Button, Typography } from '@mui/material';
import './autodesk.css';

const AutodeskViewer = ({ prepUrn }) => {
  const [viewer, setViewer] = useState(null);
  const [urn, setUrn] = useState(prepUrn);
  console.log('urn', prepUrn);

  useEffect(() => {
    let initializedViewer;
    const initializeViewer = async () => {
      initializedViewer = await initViewer(
        document.getElementById('autodesk-preview'),
      );
      setViewer(initializedViewer);
    };

    initializeViewer().then(() => {
      loadModel(initializedViewer, urn);
    });
  }, [prepUrn]);

  return (
    <div width="100%">
      <div id="autodesk-div">
        <Box
          id="autodesk-header"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <img
            id="autodesk-logo"
            height={'25px'}
            src="https://cdn.autodesk.io/logo/black/stacked.png"
            alt="Autodesk Platform Services"
          />
        </Box>
        <div id="autodesk-preview"></div>
        <div id="autodesk-overlay"></div>
      </div>
      {/* Add other React components or UI elements here */}
    </div>
  );
};

export default AutodeskViewer;
