export const getHashdata = (fullHash, hashname) => {
  const hashData = fullHash
    .substring(1) // Remove the "#" character
    .split('&') // Split the hash fragment into key-value pairs
    .reduce((acc, pair) => {
      const [key, value] = pair.split('=');
      acc[key] = value;
      return acc;
    }, {});
  return hashData[hashname];
};

export const setHash = (fullHash, hashname, hashvalue) => {
  // #main-nav=Data&data-tab=Design&design-tab=vortex
  const hashPairs = fullHash.substring(1).split('&');
  let updatedHash = '';

  for (let i = 0; i < hashPairs.length; i++) {
    const element = hashPairs[i];
    const [key, value] = element.split('=');
    console.log(element);

    if (key === hashname) {
      updatedHash += hashname + '=' + hashvalue;
      break;
    }
    updatedHash += element + '&';
  }
  console.log({ updatedHash });
  window.location.hash = updatedHash;
};
