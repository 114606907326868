import React, { createContext, useEffect } from "react";
import { getMrqData, getMrqPos, getMrqs } from "./api";
import MRQ from "./MrqInterface";
import MrqMaterials from "../MCSTab/interface/MrqMaterialsInterface";
import { SelectChangeEvent } from "@mui/material";
// import { getMrqBid } from "./BidTab/api";
// import { getBidTableRows } from "./BidTab/BidTable/BidTableBody/getRows";

interface MCSContextType {
  mrqList: MRQ[];
  mrqSelection: {
    selectedMrq: string;
    revisions: { id: string; name: string }[];
    selectedRevision: string;
  };
  mrqData: MRQ | null;
  // currentBidVendor: any[]; // Define your type if needed
  handleMrqChange: (event: SelectChangeEvent<string>) => void;
  handleRevisionChange: (event: SelectChangeEvent<string>) => void;
  setMrqList: React.Dispatch<React.SetStateAction<MRQ[]>>;
  setMrqData: React.Dispatch<React.SetStateAction<MRQ | null>>;
  // selectedVendors: string[];
  // setSelectedVendors: React.Dispatch<React.SetStateAction<string[]>>;
  // bidData: any[]; // Define your type if needed
  // setBidData: React.Dispatch<React.SetStateAction<any[]>>; // Define your type if needed
  // BidTableRows: any[]; // Define your type if needed
  // setBidTableRows: React.Dispatch<React.SetStateAction<any[]>>; // Define your type if needed
}

const MCSContext = createContext<MCSContextType>({
  mrqList: [],
  mrqSelection: {
    selectedMrq: "",
    revisions: [],
    selectedRevision: ""
  },
  mrqData: null,
  // currentBidVendor: [],
  handleMrqChange: () => { },
  handleRevisionChange: () => { },
  setMrqList: () => { },
  setMrqData: () => { },
  // selectedVendors: [],
  // setSelectedVendors: () => { },
  // bidData: [],
  // setBidData: () => { },
  // BidTableRows: [],
  // setBidTableRows: () => { },
});

function MCSContextProvider({ children }: any) {
  const [mrqList, setMrqList] = React.useState<MRQ[]>([]);
  const [mrqSelection, setMrqSelection] = React.useState({
    selectedMrq: "",
    revisions: [],
    selectedRevision: "",
  });
  const [mrqData, setMrqData] = React.useState<MRQ | null>(null);

  const [selectedVendors, setSelectedVendors] = React.useState([]);
  const [bidData, setBidData] = React.useState([]);
  const [BidTableRows, setBidTableRows] = React.useState([]);

  // Handler for selecting MRQ
  const handleMrqChange = (event: SelectChangeEvent<string>) => {
    console.log("clicked?")
    const mrqId = event.target.value as string;
    const selectedMrq = mrqList.find((mrq) => mrq.id === mrqId);
    const revisions = selectedMrq?.revs ? selectedMrq.revs : [];
    console.log("MRQ CHAGNE", mrqId, selectedMrq, revisions)
    setMrqSelection({
      selectedMrq: mrqId,
      revisions: revisions,
      selectedRevision: "",
    });
  };

  // Handler for selecting revision
  const handleRevisionChange = (event: SelectChangeEvent<string>) => {
    setMrqSelection({ ...mrqSelection, selectedRevision: event.target.value });
  };

  React.useEffect(() => {
    const fetchMrqList = async () => {
      const data = await getMrqs();
      setMrqList(data);
    };

    fetchMrqList();
  }, []);

  useEffect(() => {
    if (mrqSelection.selectedMrq && !mrqSelection.selectedRevision) {
      const fetchMrqData = async () => {
        await getMrqData(mrqSelection.selectedMrq)
          .then(res => setMrqData(res))

      }
      fetchMrqData()
    }
    else if (mrqSelection.selectedRevision) {
      const fetchMrqData = async () => {
        await getMrqData(mrqSelection.selectedRevision)
          .then(res => setMrqData(res))
      }
      fetchMrqData()
    }

  }, [mrqSelection])
  // React.useEffect(() => {
  //   const fetchMrqThenBidData = async () => {
  //     const mrq = await getMrqData(
  //       mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     );
  //     setMrqData(mrq);
  //     setSelectedVendors(mrq.vendors?.map((v) => v.name));

  //     // const bids = await getMrqBid(
  //     //   mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     // );
  //     // const existedPos = await getMrqPos(
  //     //   mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     // );
  //     // setBidData(bids);

  //     // setBidTableRows();
  //     // setBidTableRows(
  //     //   getBidTableRows({
  //     //     mrqData: mrq ? mrq : {},
  //     //     bidData: bids ? bids : [],
  //     //     existedPos,
  //     //   })
  //     // );
  //   };

  //   fetchMrqThenBidData();
  // }, [mrqSelection]);

  return (
    <MCSContext.Provider
      value={{
        mrqList,
        setMrqList,
        mrqSelection,
        mrqData,
        setMrqData,
        handleMrqChange,
        handleRevisionChange,
        // selectedVendors,
        // setSelectedVendors,
        // bidData,
        // setBidData,
        // BidTableRows,
        // setBidTableRows,
      }}
    >
      {children}
    </MCSContext.Provider>
  );
}

export { MCSContextProvider, MCSContext };
