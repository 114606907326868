import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import HeadingComponent from '../../common/components/HeadingComponent'
import { columnNames, materialDBTables, materialDbColumns } from './utils';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { minimalMuiTableProps } from '../../common/style/muiProps';
import { getAllTables, getTableData } from './api';
import User from '../UsersPage/interface/userInterface';


const MaterialDBPage = () => {
  const [tables, setTables] = useState<string[]>([])
  const [selectedTable, setSelectedTable] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [enableEditing, setEnableEditing] = React.useState(false);
  const [key, setKey] = React.useState(Math.random());

  // edited rows
  const [editedRecords, setEditedRecords] = useState([]);

  const startEditing = () => {
    setEnableEditing(true);
  }
  const stopEditing = () => {
    setEnableEditing(false);

  }
  const handleChange = (event: SelectChangeEvent<string>) => {
    console.log("change")
    setSelectedTable(event.target.value);
  };
  // console.log(selectedTable)

  useEffect(() => {
    const fetchTables = async () => {
      await getAllTables()
        .then((res: string[]) => {
          console.log(res)
          setTables(res)
          setSelectedTable(res[0])
        })
    }

    fetchTables()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await getTableData(selectedTable)
        .then(res => {
          // console.log(res)
          setTableData(res)
        })
    }
    fetchData()
    // to rerender the table on change of editing state
    setKey(Math.random());
  }, [selectedTable, enableEditing])

  // dynamic columns: from the docs
  const columns = useMemo<MRT_ColumnDef<materialDbColumns>[]>(
    () =>
      tableData.length
        ? Object.keys(tableData[0]).map((columnId) => ({
          header: columnNames[columnId as keyof materialDbColumns] ?? columnId,
          accessorKey: columnId,
          id: columnId,
          "muiEditTextFieldProps": ({ cell, row }) => ({
            onBlur: (event: any) => {
              console.log("eDTIED", { cell, row })
              setEditedRecords({ ...editedRecords, [row.id]: row.original });
            },
          })
        }))
        : [],
    [tableData],
  );

  console.log({ editedRecords })

  // let columns: any[] = []
  // if (tableData?.[0]) {
  //   const keys = (Object.keys(tableData[0]))
  //   // console.log(keys, (tableData))
  //   // console.log(data.data)
  //   columns = keys.map(key => {
  //     let tempObject: any = {}
  //     // accessorFn: (row) => row.username,
  //     tempObject["accessorKey"] = [key]
  //     tempObject["header"] = key
  //     tempObject["muiEditTextFieldProps"]
  //     // return (tempObject)
  //     return ({
  //       "accessorKey": key,
  //       "header": key,
  //       "muiEditTextFieldProps": ({ cell: any, row: any }) => ({
  //         onBlur: (event: any) => {

  //           setEditedRecords({ ...editedRecords, [row.id]: row.original });
  //         },
  //       })
  //     })
  //   })
  // }

  // console.log(columns.includes("CONN_TYPE"), columns)
  return (
    <Box p={"2rem"}>
      <HeadingComponent heading='Tables' />

      <Box maxWidth="90vw">
        <MaterialReactTable
          key={key}
          // TOdo: send setEditedRows to the columns and add it to onblur..disable edit on id column 
          columns={columns ?? []}
          data={tableData ?? []} //data is undefined on first render
          // initialState={{ showColumnFilters: true }}
          // manualFiltering
          // manualPagination
          // manualSorting
          enableEditing={enableEditing}
          enableGrouping={true}
          enableColumnActions
          // enableColumnActions={false}
          enableColumnDragging={false}
          editDisplayMode='table'
          initialState={{
            pagination: { pageSize: 10, pageIndex: 0 },
            columnOrder: [...Object.keys(columnNames)]
            // grouping: columns?.length !== 0 && columns?.some(col => col.header === "Description") ? ['Description'] : [],
            // expanded: true
          }}
          {...minimalMuiTableProps}
          renderTopToolbarCustomActions={() => (
            <Box sx={{ display: 'flex', alignItems: "baseline" }}>
              <FormControl sx={{ m: 1, minWidth: 120 }} variant="filled" size="small">
                <InputLabel id="select-label">Table</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  sx={{ backgroundColor: "white" }}
                  value={selectedTable}
                  label="Table"
                  onChange={handleChange}
                >

                  {
                    tables?.length
                      ?
                      tables.map(elem => (
                        <MenuItem value={elem}>{elem}</MenuItem>
                      ))
                      : <MenuItem value="">No Items</MenuItem>

                  }
                </Select>
              </FormControl>
              {
                enableEditing ?
                  <Button onClick={stopEditing}>Save</Button>
                  :
                  <Button onClick={startEditing}>Edit</Button>
              }
            </Box>
          )}
          renderBottomToolbarCustomActions={() => (
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Button
                color="success"
                variant="contained"
                disabled={true}
              // onClick={handleSave}
              // disabled={
              //   Object.keys(editedUsers).length === 0 ||
              //   Object.values(validationErrors).some((error) => !!error)
              // }
              >
                {/* {isUpdatingUsers ? <CircularProgress size={25} /> : 'Save'} */}
              </Button>
              {/* {Object.values(validationErrors).some((error) => !!error) && (
                <Typography color="error">Fix errors before submitting</Typography>
              )} */}
            </Box>
          )}
          defaultColumn={{
            minSize: 20, //allow columns to get smaller than default
            maxSize: 80, //allow columns to get larger than default
            size: 40, //make columns wider by default
          }}
          // rowCount={data?.meta?.totalRowCount ?? 0}
          state={{
            isLoading: columns?.length === 0,
            // showAlertBanner: isError,
            // showProgressBars: isFetching,
          }}
        />
      </Box>

    </Box>
  )
}

export default MaterialDBPage