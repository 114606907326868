import { Outlet } from 'react-router-dom';
import WSLayout from '../../common/components/layout';
// import NavBar from '../../common/components/layout/NavBar';

export default function Layout() {
  return (
    <>
      <WSLayout>
        <Outlet />
      </WSLayout>
    </>
  );
}
