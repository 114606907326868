import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import RACI from '../../raci.interface';

import { useMsal } from '@azure/msal-react';
import Timesheet from '../../../../../../TimesheetsPage/interface';
import UserList from './components/UsersList';
import AddUserForm from './components/AddUserForm';
import { useEffect, useState } from 'react';
import User from '../../../../../../UsersPage/interface/userInterface';
import { addUsersList, getUsersList } from '../../api';

interface UserDialProps {
  open: boolean;
  handleClose: () => void;
  type: string;
  allUsers: User[] | [];
  rowId: string;
}

const UserDialog = ({
  open,
  handleClose,
  type,
  allUsers,
  rowId,
}: UserDialProps) => {
  //   const { instance, accounts } = useMsal();
  //   const username = accounts[0]?.name || 'Me';
  const [users, setUsers] = useState<User[]>([]);
  useEffect(() => {
    console.log('gettting');

    getUsersList(rowId, type).then((res) => {
      setUsers(res);
    });
  }, [rowId, type]);

  const addUser = (user: User) => {
    setUsers([...users, user]);
    // console.log('users', users);
  };

  const removeUser = (index: number) => {
    const updatedUsers = [...users];
    updatedUsers.splice(index, 1);
    setUsers(updatedUsers);
  };
  const saveusers = () => {
    const emails: string[] = users.map((u) => u.email);
    console.log('saving users', emails);

    addUsersList(rowId, type, emails);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogContent>
        <Typography variant="h5" gutterBottom>
          {type === 'acc' && 'Accountable'}
          {type === 'cons' && 'Consulted'}
          {type === 'inf' && 'Informed'}
        </Typography>
        <UserList users={users} removeUser={removeUser} />
        <AddUserForm allUsers={allUsers} addUser={addUser} />
        <Button
          onClick={saveusers}
          sx={{ marginTop: '1rem' }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default UserDialog;
