export const drawingCols = [
  {
    label: "Drawing Title",
    key: "sheetNumber",
  },
  {
    label: "Drawing #",
    key: "name",
  },
  {
    label: "Revision",
    key: "revision",
  },
  {
    label: "Sheet #",
    key: "sheetNumber",
  },
  {
    label: "Source",
    key: "source",
  },
];
