import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';

export default function SelectMaterialType() {
  return (
    <FormControl>
      {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="tagMaterials"
          control={<Radio />}
          label="Tag Materials"
        />
        <FormControlLabel
          value="plates"
          control={<Radio />}
          label="Plates"
          sx={{ mx: 1 }}
        />
        <FormControlLabel
          value="bulkMaterials"
          control={<Radio />}
          label="Bulk Materials"
          sx={{ mx: 1 }}
        />
      </RadioGroup>
    </FormControl>
  );
}
