import * as React from 'react';
import { Box, Button } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  bulkedMaterialsCols,
  taggedMaterialsCols,
} from '../../ConsolidateModal/cols';
import { DrawingBomContext } from '../../../DrawingBom.context';
import { lightTable } from '../../../../../../common/styles/muiProps';

export default function MaterialsTable({ drawing }) {
  const [rowSelection, setRowSelection] = React.useState({});

  const { changeSelectionMaterials } = React.useContext(DrawingBomContext);

  // console.log("outside", rowSelection);
  // const onRowSelection = (e) => {
  //   setRowSelection(e);
  //   const res = e();
  //   console.log("inside", res);
  //   const selectedMaterialsIds = Object.keys(rowSelection);

  //   const selectedMaterials = selectedMaterialsIds.map((id) => {
  //     return drawing.materials.find((m) => m.id === id);
  //   });
  //   const drawingSelectedMaterials = {};

  //   drawingSelectedMaterials[drawing.id] = selectedMaterials;

  //   changeSelectionMaterials(drawingSelectedMaterials);
  // };

  const table = useMaterialReactTable({
    data: drawing.materials,
    columns: drawing.materials?.[0].taggedMaterial?.id
      ? taggedMaterialsCols
      : bulkedMaterialsCols,
    enableRowSelection: true,
    ...lightTable,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { maxHeight: '400px' } },
    getRowId: (originalRow) => originalRow.id,
    // onRowSelectionChange: onRowSelection,
    // state: { rowSelection },
  });

  React.useEffect(() => {
    const selectedMaterialsIds = Object.keys(table.getState().rowSelection);

    const selectedMaterials = selectedMaterialsIds.map((id) => {
      return drawing.materials.find((m) => m.id === id);
    });
    const drawingSelectedMaterials = {};

    drawingSelectedMaterials[drawing.id] = selectedMaterials;

    changeSelectionMaterials(drawingSelectedMaterials);
  }, [table.getState().rowSelection]);

  const bulkedTable = { ...table, columns: bulkedMaterialsCols };
  const taggedTable = { ...table, columns: bulkedMaterialsCols };

  return (
    <Box sx={{ maxHeight: 400, backgroundColor: '#fff' }}>
      <MaterialReactTable
        table={
          bulkedTable
          // drawing.materials?.[0].taggedMaterial?.id ? taggedTable : bulkedTable
        }
      />
    </Box>
  );
}
