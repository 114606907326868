export const materialDBTables = [
    "Manual_Valves",
    "ORIFICE_FLANGE",
    "POSITION_SWITCHES",
    "PRESSURE_GAUGES",
    "SSO_VENT"
]


export const columnNames = {
    "S_No":"",
    "Components": "Components",
    "Size": "Size",
    "Schedule / Rating": "Schedule / Rating",
    "Rating - Schedule": "Rating - Schedule",
    "Ends": "Ends",
    "Material": "Material",
    "Additional Notes": "Additional Notes",
    "Material Description": "Material Description",
    "Manufacturer": "Manufacturer",
    "MFR#":"MFR#",
    "IFS Number":"IFS Number"
} as const;

export type materialDbColumns = {
    "S_No":number,
    "Components": string,
    "Size": string,
    "Schedule / Rating": string,
    "Rating - Schedule": string,
    "Ends": string,
    "Material": string,
    "Additional Notes": string,
    "Material Description": string,
    "Manufacturer": string,
    "MFR#": string,
    "IFS Number": string,
}