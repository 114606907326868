import React, { createContext } from 'react';
import { getDrawingData } from '../DrawingRegisterTab/api';
import { createMrq } from './api';
import { MCSContext } from '../MCS.context';
import { useNavigate, useParams } from 'react-router-dom';

const DrawingBomContext = createContext({
  handleAddingNewDrawing: () => {},
  onRemoveAll: () => {},
  onConsolidate: () => {},
  openConsolidateModal: false,
  setOpenConsolidateModal: () => {},
  selectedDrawings: [],
  removeSelectedDrawing: () => {},
  selectionMaterials: {},
  setSelectionMaterials: () => {},
  consolidatedMaterials: [],
});

let currentDate = new Date();
const offset = currentDate.getTimezoneOffset();
currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

function DrawingBomProvider({ children }) {
  const { setMrqData, project, setMrqSelection, setCurrentTab } =
    React.useContext(MCSContext);

  const [selectedDrawings, setSelectedDrawings] = React.useState([]);
  const [selectionMaterials, setSelectionMaterials] = React.useState({});
  const [consolidatedMaterials, setConsolidatedMaterials] = React.useState([]);
  // selectionMaterials: {drawingId: [materials], }
  const [openConsolidateModal, setOpenConsolidateModal] = React.useState(false);

  const [generatingData, setGeneratingData] = React.useState([]);

  const [isMrqFormVisible, setIsMrqFormVisible] = React.useState(false);

  const initialMrqDetails = {
    projectId: project.id,
    projectName: project.name || '',
    mrqDate: currentDate.toISOString().split('T')[0],
  };
  const [mrqDetails, setMrqDetails] = React.useState(initialMrqDetails);

  const handleAddingNewDrawing = async ({ drawing }) => {
    const materials = await getDrawingData(drawing.id);

    if (materials?.[0]?.tagNumber) {
      const taggedMaterials = materials.map((m) => {
        const { component, size } = m.taggedMaterial;

        const description = !m.description
          ? component + ' ' + size
          : m.description;

        return { ...m, description };
      });

      setSelectedDrawings([
        ...selectedDrawings,
        { ...drawing, materials: taggedMaterials },
      ]);
    } else {
      setSelectedDrawings([...selectedDrawings, { ...drawing, materials }]);
    }
  };

  const removeSelectedDrawing = (id) => {
    setSelectedDrawings(
      selectedDrawings.filter((drawing) => drawing.id !== id),
    );
    setSelectionMaterials[id] = [];
    setSelectionMaterials({ ...setSelectionMaterials });
  };

  const onRemoveAll = () => {
    setSelectedDrawings([]);
    setSelectionMaterials({});
  };

  const handleOpenConsolidateModal = () => setOpenConsolidateModal(true);
  const handleCloseConsolidateModal = () => setOpenConsolidateModal(false);

  const obj = {};

  consolidatedMaterials.forEach((m) => {
    if (m.tagNumber) {
      const previousState = obj[m.description];
      if (previousState?.description) {
        obj[m.description] = {
          ...previousState,
          quantity: Number(previousState.quantity) + Number(m.quantity),
        };
      } else {
        obj[m.description] = { ...m };
      }
    } else {
      const previousState = obj[m.materialCode];

      if (previousState?.description) {
        obj[m.materialCode] = {
          ...previousState,
          // materialsIds: [...previousState.materialsIds, m.id],
          softwareId: m.softwareId,
          item: previousState.item + ', ' + m.item,
          markNo: previousState.markNo + ', ' + m.markNo,
          description: m.description,
          quantity: Number(previousState.quantity) + Number(m.quantity),
          totalWtInKgs:
            Math.round(
              (Number(previousState.totalWtInKgs) + Number(m.totalWtInKgs)) *
                100,
            ) / 100,
        };
      } else {
        obj[m.materialCode] = { ...m };
      }
    }
  });

  const consolidatedMaterialsData = Object.values(obj).map((m) => {
    const materials = consolidatedMaterials.filter(
      (material) => material.materialCode === m.materialCode,
    );
    return { ...m, materials };
  });

  const consolidatedTaggedMaterialsData = Object.values(obj).map((m) => {
    const materials = consolidatedMaterials.filter(
      (material) => material.description === m.description,
    );
    return { ...m, materials };
  });

  const initialGeneratingData = consolidatedMaterialsData?.map((m) => ({
    softwareId: m.softwareId,
    materialNo: m.materialNo,
    actualTotalWeight: m.totalWtInKgs,
    materials: m.materials,
  }));

  function changeSelectionMaterials(drawingSelectedMaterials) {
    console.log('from context', drawingSelectedMaterials);
    setSelectionMaterials({
      ...selectionMaterials,
      ...drawingSelectedMaterials,
    });
  }

  function onConsolidate() {
    let materials = [];

    setIsMrqFormVisible(false);
    setMrqDetails(initialMrqDetails);
    setGeneratingData(initialGeneratingData);

    console.log({ selectionMaterials });
    Object.values(selectionMaterials).forEach((val) => {
      console.log({ val });
      val.forEach((material) => materials.push(material));
    });

    console.log({ materials });
    if (materials[0]) {
      setConsolidatedMaterials(materials);
      handleOpenConsolidateModal(true);
    } else {
      alert('No materials selected');
    }
  }

  const onClickAddMrq = () => {
    setIsMrqFormVisible(true);
  };

  const onMrqDetailsChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, value);
    setMrqDetails({ ...mrqDetails, [name]: value });
  };

  const onCreateMrq = async () => {
    const createdMrq = {
      ...mrqDetails,
      mrqMaterials: consolidatedMaterials?.[0].tagNumber
        ? consolidatedTaggedMaterialsData
        : generatingData,
    };

    if (!createdMrq.mrqNumber) {
      alert('Please Insert MRQ Number.');
    } else {
      const res = await createMrq({ createdMrq });
      if (res.id) {
        setOpenConsolidateModal(false);
        setMrqSelection({
          selectedMrq: '',
          revisions: [],
          selectedRevision: '',
        });

        setMrqData(res);
        console.log('fromDrawingBomContext :', res);

        setCurrentTab(1);
      }
    }
  };

  return (
    <DrawingBomContext.Provider
      value={{
        handleAddingNewDrawing,
        onRemoveAll,
        onConsolidate,
        openConsolidateModal,
        handleCloseConsolidateModal,
        selectedDrawings,
        removeSelectedDrawing,
        selectionMaterials,
        setSelectionMaterials,
        changeSelectionMaterials,
        consolidatedMaterials,
        consolidatedTaggedMaterialsData,
        consolidatedMaterialsData,
        generatingData,
        setGeneratingData,
        initialGeneratingData,
        isMrqFormVisible,
        onClickAddMrq,
        mrqDetails,
        onMrqDetailsChange,
        onCreateMrq,
      }}
    >
      {children}
    </DrawingBomContext.Provider>
  );
}

export { DrawingBomProvider, DrawingBomContext };
