import { Card, Skeleton, Stack } from '@mui/material'
import React from 'react'

const SkeletonCard = () => {
    return (
        <Card sx={{ maxWidth: 345, height: 250, p: 1 }}>
            <Stack spacing={1}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={290} height={80} />
                <Skeleton variant="rounded" width={290} height={80} />
            </Stack>
        </Card>
    )
}

export default SkeletonCard