import axios from '../../../common/api/axios';

export const addMrqVendors = async ({ mrqId, selectedVendorsIds }) => {
  const { data } = await axios.post('/mrq/add-vendors/' + mrqId, {
    vendorsId: selectedVendorsIds,
  });
  return data;
};

export const getMrqBid = async (mrqId) => {
  const { data } = await axios.get(`/bid/mrq/${mrqId}`);
  return data;
};

export const postBidSelection = async ({ mrqId, bidsSelection }) => {
  const { data } = await axios.post(
    '/bid/selection/mrq/' + mrqId,
    bidsSelection,
  );
  return data;
};

export const getCompanyVendors = async () => {
  const getCompanyVendorsApi = '/vendor';
  const { data } = await axios.get(getCompanyVendorsApi);
  return data;
};
