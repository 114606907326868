import * as React from 'react';
import { MCSContextProvider } from './MCS.context';

import Main from './Main';
import { useParams } from 'react-router-dom';
import axios from '../../common/api/axios';

export default function MCSPage() {
  const { projectId } = useParams();

  const [project, setProject] = React.useState({ id: projectId });

  React.useEffect(() => {
    const func = async () => {
      try {
        const res = await axios.get('/project/' + projectId);
        console.log('res', res);
        setProject(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    func();
  }, []);
  return (
    <MCSContextProvider project={project}>
      <Main />
    </MCSContextProvider>
  );
}
