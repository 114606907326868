import axios from "../../common/api/axios"

export const getTableData = async (table: string) => {
    const { data } = await axios.get("/materialdb/" + table);
    return data;
}

export const getAllTables = async () => {
    const { data } = await axios.get("/materialdb/allTables");
    return data;
}

export const editTable = async (table: string, tableData: any) => {
    const { data } = await axios.put("/materialdb/"+table, tableData);
    return data;
}