import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import DrawingSelection from '../DrawingRegisterTab/components/DrawingSelection';
import MaterialTable from './MaterialTable';
import POSelection from './POSelection';
import { getAllProjectMaterials, getReconcileMaterials } from './api';
import { MCSContext } from '../MCS.context';
import LabelledBox from '../../../common/components/LabelledBox';

export default function PoReconcile() {
  const { project } = React.useContext(MCSContext);

  const [selectedDrawings, setSelectedDrawings] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);

  const handleAddingNewDrawing = async ({ drawing }) => {
    const materials = await getReconcileMaterials({
      parent: 'drawing',
      parentId: drawing.id,
    });
    setMaterials(materials);
    setSelectedDrawings([...selectedDrawings, { ...drawing }]);
  };

  const reconcileMaterialsOptions = [
    { value: 'All Project Materials', component: '' },
    {
      value: 'Select Drawing Materials',
      component: (
        <DrawingSelection
          selectedDrawings={selectedDrawings}
          handleAddingNewDrawing={handleAddingNewDrawing}
        />
      ),
    },
    {
      value: 'Select PO Materials',
      component: <POSelection setMaterials={setMaterials} />,
    },
  ];
  const [value, setValue] = React.useState(reconcileMaterialsOptions[0].value);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  React.useEffect(() => {
    getAllProjectMaterials(project.id, setMaterials);
  }, []);

  React.useEffect(() => {
    value === reconcileMaterialsOptions[0].value &&
      getAllProjectMaterials(project.id, setMaterials);
  }, [value]);

  return (
    <Box>
      <LabelledBox label="Project Materials :" sx={{ ml: 5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              pr: 2,
              borderRight:
                value !== reconcileMaterialsOptions[0].value &&
                '1px solid #aaa',
            }}
          >
            <FormControl>
              <RadioGroup value={value} onChange={handleChange}>
                {reconcileMaterialsOptions.map((opt) => {
                  return (
                    <FormControlLabel
                      value={opt.value}
                      control={<Radio />}
                      label={opt.value}
                      key={opt.value}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box sx={{ pl: 2 }}>
            {reconcileMaterialsOptions.find((o) => o.value === value).component}
          </Box>
        </Box>
      </LabelledBox>

      <Box sx={{ mx: 5 }}>
        <MaterialTable materials={materials} />
      </Box>
    </Box>
  );
}
