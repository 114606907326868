import React from 'react';
import POSelection from '../PoReconcile/POSelection';
import LabelledBox from '../../../common/components/LabelledBox';
import MrqMaterialsTable from '../MRQTab/components/MrqMaterialsTable';
import { Box } from '@mui/material';

export default function PoManger() {
  return (
    <Box sx={{ ml: 3 }}>
      <LabelledBox label="Project Materials :">
        <POSelection />
      </LabelledBox>

      <MrqMaterialsTable data={[]} pos={[]} />
    </Box>
  );
}
