
import React, { createContext, useContext, ReactNode, useState } from 'react';
type user = {
  name: string;
  role: string;
};

interface authContextInterface {
  user?: user;
  login: (user: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<authContextInterface>({
  user: undefined,
  login: () => { },
  logout: () => { },
});

const AuthContextProvider = (props: {
  children:
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | Iterable<React.ReactNode>
  | React.ReactPortal
  | null
  | undefined;
}) => {
  const [user, setUser] = useState<user | undefined>(undefined);

  const login = (userName: string): void => {
    setUser({ name: userName, role: 'admin' });
  };

  
  const logout = () => {
    setUser(undefined);
  };
  const contectValues: authContextInterface = {
    user,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contectValues}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
