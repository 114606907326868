import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
// import LabelledBox from '../../../../../../../common/components/LabelledBox'
import { Box } from '@mui/material';
// import { lightTable } from '../../../../../../../common/style/mrtProps'
import ActionBtns from './ActionBtns';
import { bulkCols, mrqMaterialsColumns, taggedCols } from './utils';
import { lightTable } from '../../../../../common/styles/muiProps';
import LabelledBox from '../../../../../common/components/LabelledBox';
import { selectedTaggedMaterialsCols } from '../../../DrawingBom/components/ConsolidateModal/cols';
import { MCSContext } from '../../../MCS.context';
import React from 'react';
// import AutoCompleteCreatable from '../../../../../../../common/components/AutoCompleteCreatable'

function groupMaterials(data) {
  const bulkMaterialMap = {};
  const taggedMaterialMap = {};

  data.forEach((mrqMaterial) => {
    // console.log(mrqMaterial);
    const materialCode = mrqMaterial?.materialCode || '';
    const tagNumber =
      mrqMaterial?.materials?.[0]?.taggedMaterial?.tagNumber || '';
    const item = mrqMaterial?.materials?.[0]?.bulkMaterial?.item || '';
    const description = mrqMaterial?.description;
    const quantity = parseFloat(mrqMaterial?.quantity) || 0;
    const poNumber = mrqMaterial?.po?.poNumber;
    const poDate = mrqMaterial?.po?.poDate;
    const vendor = mrqMaterial?.po?.poMaterials[0]?.vendor?.name;
    const mrvNumber = mrqMaterial?.mrv?.mrvNumber;
    const mrvDate = mrqMaterial?.mrv?.mrvDate;
    const srvNumber = mrqMaterial?.srv?.srvNumber;
    const srvDate = mrqMaterial?.srv?.srvDate;
    const heatNumber = mrqMaterial?.heatNumber;
    const mrqMaterialId = mrqMaterial?.id;
    const remarks = mrqMaterial?.remarks;
    const materials = mrqMaterial?.materials?.map((m) => {
      return { tagNumber: m.taggedMaterial.tagNumber };
    });

    // If its a bulk material group by description...
    if (item) {
      if (!bulkMaterialMap[item]) {
        bulkMaterialMap[description] = {
          item,
          description,
          materialCode,
          quantity: 0,
          poNumber,
          poDate,
          vendor,
          mrvNumber,
          mrvDate,
          srvNumber,
          srvDate,
          heatNumber,

          id: [],
        };
      }
      bulkMaterialMap[description].quantity += quantity;
      bulkMaterialMap[description].id.push(mrqMaterialId);
    }

    // If its a tagged material, group with material code
    if (tagNumber) {
      if (!taggedMaterialMap[tagNumber]) {
        taggedMaterialMap[tagNumber] = {
          description,
          tagNumbers: new Set(),
          quantity,
          poNumber,
          poDate,
          mrvNumber,
          mrvDate,
          srvNumber,
          srvDate,
          heatNumber,
          remarks,
          id: [],
          materials,
        };
      }
      taggedMaterialMap[tagNumber].id.push(mrqMaterialId);
      if (tagNumber) {
        taggedMaterialMap[tagNumber].tagNumbers.add(tagNumber);
      }
    }
  });

  const bulkMaterialArray = Object.values(bulkMaterialMap);
  const taggedMaterialArray = Object.values(taggedMaterialMap);

  return [...bulkMaterialArray, ...taggedMaterialArray];
}

const MrqMaterialsTable = ({ data, pos }) => {
  const handleSaveItem = async (values) => {};

  const { mrqData } = React.useContext(MCSContext);
  const formattedData = groupMaterials(
    mrqData?.mrqMaterials || mrqData?.[0]?.mrqMaterials || [],
  );

  console.log({ mrqDataTableeeeeeeeeeeeeeee: data });
  console.log('from mrqMaterials table : ', data);
  const generalTableAttrs = {
    data: formattedData,
    enableRowSelection: true,

    positionActionsColumn: 'last',
    onEditingRowSave: { handleSaveItem },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel();

      return (
        <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
          <ActionBtns selectedRows={selectedRows} />
        </Box>
      );
    },
    ...lightTable,
    enableTopToolbar: true, //overriding lightTable
    // state: { columnVisibility },
    // onColumnVisibilityChange: { setColumnVisibility },
  };
  const taggedTable = useMaterialReactTable({
    ...generalTableAttrs,
    columns: [...selectedTaggedMaterialsCols, ...mrqMaterialsColumns],
  });

  const bulkTable = useMaterialReactTable({
    ...generalTableAttrs,
    columns: bulkCols,
  });
  return (
    <LabelledBox label="Mrq Materials">
      <MaterialReactTable
        table={
          data && data[0]?.materials[0]?.bulkMaterial?.item
            ? bulkTable
            : taggedTable
        }
      />
      {/* <Button variant='contained' sx={{ mt: 1 }}>Save</Button> */}
    </LabelledBox>
  );
};

export default MrqMaterialsTable;
