import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Grid, Stack } from '@mui/material';
import SoftwareCard from './SoftwareCard';
import { useMsal } from '@azure/msal-react';
import { Software } from './interface';
import { getSoftwares } from './api';
import HeadingComponent from '../../common/components/HeadingComponent';
import axios from '../../common/api/axios';
import SkeletonCard from './SkeletonCard';
import DownloadingCard from './DownloadingCard';


const sortByEstimatedRelease = (a: Software, b: Software) => {
    if (!a.estimatedRelease && b.estimatedRelease) {
        return -1; // a comes before b
    } else if (a.estimatedRelease && !b.estimatedRelease) {
        return 1; // b comes before a
    } else {
        return 0; // leave order unchanged
    }
};

const SoftwarePage: React.FC = () => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { instance, accounts } = useMsal();
    const username = accounts[0]?.name;

    const [formData, setFormData] = useState<{
        selectedFile: File | null;
        name: string;
        repository: string;
        description: string;
    }>({
        selectedFile: null,
        name: '',
        repository: '',
        description: '',
    });

    const [softwares, setSoftwares] = useState<Software[]>([]);

    useEffect(() => {
        const fetchSoftwares = async () => {
            try {
                const res = await getSoftwares();
                // setSoftwares(res);

                // Sort the softwares array to push the ones with estimatedrelease to the end
                const sortedSoftwares = [...res].sort(sortByEstimatedRelease);
                setSoftwares(sortedSoftwares);
                setIsLoading(false)
            } catch (error) {
                console.error('Error fetching softwares:', error);
            }
        };
        fetchSoftwares();
    }, []);


    const handleDownload = async (software: Software) => {
        console.log('Got software:', software);
        setIsDownloading(true);
        try {
            const response = await axios.get(`/software/download/${software.id}`, {
                responseType: 'blob',
            })
                .then(res => {
                    var blob = new Blob([res.data], { type: 'application/octet-stream' });
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    console.log("created a")
                    a.href = url;
                    a.download = `${software.name}.zip`;
                    a.click();
                    console.log("got response")
                    window.URL.revokeObjectURL(url);
                    setIsDownloading(true);
                })

        } catch (error) {
            console.error('Error downloading software:', error);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <Box
            sx={{
                p: "2rem",
                // flexGrow: 1
            }}
        >
            {/* Heading */}
            <HeadingComponent heading='Softwares' />

            {
                isDownloading ?
                    <DownloadingCard />
                    :
                    <></>
            }
            {/* The Software Grid */}
            <Grid container spacing={{ xs: 1, md: 1, lg: 1 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 16 }} direction="row">
                {
                    // show skeleton cards when loading
                    isLoading ?
                        <Stack direction={"row"} gap={1}>
                            {
                                Array.from(new Array(3)).map((item, index) => (
                                    <SkeletonCard />
                                ))
                            }
                        </Stack>
                        :
                        // once loaded display softwares
                        softwares.length ? (
                            softwares.map((software, i) => (
                                <Grid key={i} item xs={3} sm={4} md={3} lg={3} xl={3}>
                                    <SoftwareCard title={software.name} description={software.description} imagePath={software.imagePath} handleDownload={handleDownload} software={software} />
                                </Grid>
                            ))
                        ) : (
                            <></>
                        )}
            </Grid>
        </Box>
    );
};

export default SoftwarePage;
