import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, Outlet, useParams } from 'react-router-dom';
import { paths } from '../projectsRoutes';
import VerticalTabPanel from './components/VerticalTabPanel';
import MCSTab from './tabs/MCSTab';
import { LinkTab } from '../../../common/components/tabs/LinkTab';
import TaskListTab from './tabs/PunchlistTab';
import Project from '../interfaces/projectInterface';
import { getProject } from '../api';
import DCSTab from './tabs/DCSTab';
// import SoftwareTab from './tabs/SoftwareTab';
import AutodeskViewer from './tabs/AutodeskViewerTab';
import MRQTab from './tabs/MRQTab';
import { Typography } from '@mui/material';
import DocumentTransmittalTab from './tabs/DocumentTransmittalTab';
import RACITab from './tabs/RACI';
import { getProjectRACI } from './tabs/RACI/api';

export default function SingleProjectPage() {
  const [value, setValue] = React.useState(0);
  const { projectTab, projectId } = useParams();
  const [ projectData, setProjectData] = React.useState<Project>();

  React.useEffect(() => {
    const fetchProjectData = async () => {
      await getProject(projectId).then((res) => setProjectData(res));
    };
    fetchProjectData();
  }, []);

  // const tabs = ["DCS", "MCS", "PCS", "Software"]
  const tabs = [
    {
      label: 'DCS',
      tabPanel: <DCSTab projectData={projectData} />,
    },
    {
        label: "MRQ",
        tabPanel: <MRQTab />
    },
    {
      label: 'Tasks',
      tabPanel: <TaskListTab project={projectData} />,
    },
    {
      label: 'RACI',
      tabPanel: <RACITab getItems={getProjectRACI} />,
    },
    // {
    //     label: "2D3D",
    //     tabPanel: <AutodeskViewer />
    // },
    // {
    //     label: "Document Transmittal",
    //     tabPanel: <DocumentTransmittalTab />
    // }
  ];

  React.useEffect(() => {
    // apply change in tabs
    let currentTab = tabs.findIndex((tab) => tab.label === projectTab);
    currentTab = currentTab === -1 ? 0 : currentTab;
    setValue(currentTab);
  }, [projectTab]);

  return (
    <Box sx={{ width: '100%', p: 1, height: '100%' }}>
      <Tabs
        value={value}
        textColor="secondary"
        aria-label="nav tabs example"
        role="navigation"
        sx={{
          position: 'sticky',
          top: '50px',
          backgroundColor: '#f8f9fa',
          zIndex: 2,
          '.MuiTabs-flexContainer': {
            alignItems: 'center',
          },
        }}
      >
        {tabs.map((tab, index) => (
          <LinkTab
            key={index}
            label={tab.label}
            to={tab.label}
            base={`${paths.myProjects}/${projectId}`}
          />
        ))}
        <Typography variant="h6" color={'primary'}>
          - {projectData?.name.toUpperCase()} -
        </Typography>
      </Tabs>
      {tabs.map((tab, index) => (
        <VerticalTabPanel key={index} value={value} index={index}>
          {tab.tabPanel}
        </VerticalTabPanel>
      ))}
    </Box>
  );
}
