import React, { useEffect, useState } from 'react'
import Wheel from './Wheel'


const HeroSection = () => {

    const data = [
        {
            heading: "Engineering Design Automation",
            paragraph: "Automate the Creation of 3D Models and Generate 2d Drawings and BOM"
        },
        {
            heading: "Estimation Automation",
            paragraph: "Efficiently estimate Project Costs and Man-hours, ensuring Accuracy and Optimizing Resource allocation."
        },
        {
            heading: "Product Lifecycle Management",
            paragraph: "Seamlessly track products from start to finish, maintaining comprehensive and traceable records."
        },
        {
            heading: "Material Control System",
            paragraph: "Monitor and Optimize the handling of Materials throughout the Project."
        },
        {
            heading: "Project Management System",
            paragraph: "Interactive graphs and dashboards for Real-Rime Project Status Updates and Tracking."
        },
        {
            heading: "Document Control System",
            paragraph: "Keep your Documents Current, Secure and Accessible"
        },
        {
            heading: "Project Control System",
            paragraph: "Project Oversight with Sector-specific Task Management."
        },
        {
            heading: "Asset Management System",
            paragraph: "Real-time Replication and Analysis of Physical Entities with Digital Twin."
        }
    ];


    var btns = []
    var titles = []
    var paras = []
    let currentElement = 0
    var wheel = ""

    window.addEventListener("load", () => {

        btns = document.querySelectorAll(".btn")
        titles = document.querySelectorAll(".wheel-title")
        paras = document.querySelectorAll(".wheel-para")
        currentElement = 1
        wheel = document.getElementById('Wheel');
    })

    // manual navigation for the wheel
    var manualNav = function (manual) {
        if (btns?.length) {
            btns.forEach((btn) => {
                btn.classList.remove("active")
            })
            titles.forEach((title) => {
                title.classList.remove("active")
            })
            paras.forEach((para) => {
                para.classList.remove("active")
            })

            btns[manual].classList.add("active")
            titles[manual].classList.add("active")
            paras[manual].classList.add("active")
        }


        if (wheel) {
            wheel.style.transform = 'rotate(' + (manual) * -45 + 'deg)';
        }

    }


    btns?.forEach((btn, i) => {
        btn.addEventListener("click", () => {
            manualNav(i)
            currentElement(i)
        })
    })


    // auto navigate for the wheel
    var repeat = function () {
        let active = document.getElementsByClassName("active");
        let i = 1;

        var repeater = () => {
            setTimeout(() => {
                if (btns?.length) {
                    // remove active elements
                    [...active].forEach((elem) => {
                        elem.classList.remove("active")
                    })

                    btns[i].classList.add("active")
                    titles[i].classList.add("active")
                    paras[i].classList.add("active")
                    if (wheel) {
                        wheel.style.transform = 'rotate(' + (i) * -45 + 'deg)';
                    }
                    i++;

                    if (btns.length === i) {
                        i = 0;
                    }
                    repeater()
                    if (i >= btns.length) {
                        return
                    }
                }

            }, 5000);
        }
        repeater()
    }

    repeat()

    return (
        <div className="hero-section">
            <div className="text-container">
                <h1>End To End</h1>
                <h2>
                    Digital
                    <br />
                    <span className='designed-text' data-text="Transformation.">Transformation.</span>
                </h2>
                <p className='hero-description'>
                    Empower your transformation with our centralized data powerhouse. Streamline operations, foster collaboration, and ignite innovation seamlessly.                </p>
            </div>
            <div className='navigation'>
                <div>
                    <Wheel />
                    <div className="wheel-navigation">
                        <div className="navigation-btns">
                            <div className="btn navigation-dot" id="radio1" ></div>
                            <div className="btn navigation-dot" id="radio2" ></div>
                            <div className="btn navigation-dot" id="radio3" ></div>
                            <div className="btn navigation-dot" id="radio4" ></div>
                            <div className="btn navigation-dot" id="radio5" ></div>
                            <div className="btn navigation-dot" id="radio6" ></div>
                            <div className="btn navigation-dot" id="radio7" ></div>
                            <div className="btn navigation-dot" id="radio8" ></div>
                        </div>
                        {/* <!-- Add more dots for each rotation angle --> */}
                    </div>
                </div>
                {/* <div className={`nav-description ${isVisible ? 'track-in' : 'track-out'}`}> */}
                <div className={`nav-description-container`}>
                    <div className="nav-description">
                        {
                            data.map((elem, i) => (
                                <>
                                    {
                                        i === 0 ?
                                            <>
                                                <h4 className="wheel-title active">{elem.heading || ""}</h4>
                                                <p className='wheel-para active'>{elem.paragraph || ""}</p>
                                            </>
                                            :


                                            <>
                                                <h4 className="wheel-title">{elem.heading || ""}</h4>
                                                <p className='wheel-para'>{elem.paragraph || ""}</p>
                                            </>
                                    }
                                </>

                            ))
                        }
                    </div>
                    {/* <h3>Data abut object</h3>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla perferendis minima ad eligendi ab iste, eos molestiae praesentium modi delectus illo incidunt eaque, tempore, doloribus impedit. Quaerat eligendi repellat est.</p> */}
                </div>
            </div>
            {/* <h5 id="changing-text"></h5> */}
        </div>
        // </section>
    )
}

export default HeroSection