import { Navigate, createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Layout from '../pages/Root/RootLayout';
import ErrorPage from '../pages/ErrorPage';
import { projectsRoutes } from '../pages/ProjectsPage/projectsRoutes';
import { AuthProtected } from './AuthProtectedOutlet';
import LoginPage from '../pages/LoginPage';
import { mainPaths } from './paths';
import SoftwarePage from '../pages/SoftwarePage';
import Home2 from '../pages/Home/Home2';
import { taskRoutes } from '../pages/PunchlistPage/taskRoutes';
import MaterialDBPage from '../pages/MaterialDBPage';
import TimesheetsPage from '../pages/TimesheetsPage';
import RaciPage from '../pages/RaciPage';
import MrqPage from '../pages/MrqPage';
import MCSPage from '../pages/MCS3';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to="/home" />,
      },
      {
        path: mainPaths.login,
        element: <LoginPage />,
      },
      {
        path: '',
        element: <AuthProtected />,
        children: [
          {
            path: mainPaths.software,
            element: <SoftwarePage />,
          },
          {
            path: mainPaths.materialDb,
            element: <MaterialDBPage />,
          },
          {
            path: mainPaths.timesheets,
            element: <TimesheetsPage />,
          },
          {
            path: mainPaths.raci,
            element: <RaciPage />,
          },
          {
            path: mainPaths.mcs,
            element: <MCSPage />,
          },
          {
            path: mainPaths.mrqs,
            element: <MrqPage />,
          },
          ...projectsRoutes,
          ...taskRoutes,
        ],
      },
    ],
    errorElement: <ErrorPage />,
  },
  {
    path: '/home',
    // element: <Home3 />,
    // element: <HomePage />,
    element: <Home2 />,
  },
]);
