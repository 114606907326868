import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Row from "./Row";
import { drawingCols } from "./utils";
import { Box } from "@mui/material";
import { DrawingBomContext } from "../../../DrawingBom.context";

export default function DrawingBomTable() {
  const {
    selectedDrawings,
    removeSelectedDrawing,
    selectionMaterials,
    setSelectionMaterials,
  } = React.useContext(DrawingBomContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {drawingCols.map((col) => {
              return (
                <TableCell
                  key={col.label}
                  sx={{ fontweight: "bold", fontSize: "17px" }}
                >
                  {col.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {selectedDrawings.map((row) => (
            <Row
              key={row.id}
              row={row}
              removeSelectedDrawing={removeSelectedDrawing}
              selectionMaterials={selectionMaterials}
              setSelectionMaterials={setSelectionMaterials}
            />
          ))}
        </TableBody>
      </Table>
      {!selectedDrawings[0] && (
        <Box sx={{ textAlign: "center", p: 4, color: "#aaa" }}>
          No Drawing is Selected yet. Please, Add Some Drawing.
        </Box>
      )}
    </TableContainer>
  );
}
