import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  MenuItem,
  Button,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ChatIcon from '@mui/icons-material/Chat';
import PostAddIcon from '@mui/icons-material/PostAdd';
import axios from '../../../../../../common/api/axios';
import { useDCS } from './DCSContext';

const RACIColumns = ({
  handleButtonClick,
  // allUsers,
  currentUser,
  openNewRaciModal,
  openVersionNewModal,
  // urnLoaded,
}) => {
  const [isRaciModalOpen, setRaciModalOpen] = useState(false);
  const [isVersionModalOpen, setVersionModalOpen] = useState(false);
  const [currentRaciId, setCurrentRaciId] = useState(null);
  // const [newRaciData, setNewRaciData] = useState({ document: '', responsible: '', description: '' });
  const [newVersionData, setNewVersionData] = useState({
    version: '',
    date: '',
    changes: '',
    link: '',
  });
  const {
    racis,
    allUsers,
    sharepointDocuments,
    urnLoaded,
    addRaci,
    updateRaciData,
    getTimesheetsData,
    createTimesheetData,
    updateTimesheetData,
    deleteTimesheetData,
    getCommentsData,
    createCommentData,
    documentList,
  } = useDCS();
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const handleCreateVersion = async () => {
    await axios.post(`/api/raci/${currentRaciId}/versions`, newVersionData);
    setVersionModalOpen(false);
  };

  const columns = useMemo(
    () => [
      {
        id: 'document',
        header: 'Document Info',
        columns: [
          {
            accessorKey: 'document',
            header: 'Document',
            size: 120, // Reduced width
            Cell: ({ cell, row }) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <IconButton onClick={() => toggleRow(row.index)}>
                  {expandedRows[row.index] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </IconButton>
                {cell.getValue()}
              </Box>
            ),
          },
          {
            accessorKey: 'docNum',
            header: 'Doc #',
            size: 10, // Reduced width
          },
          {
            accessorKey: 'revNum',
            header: 'Rev #',
            size: 10, // Reduced width
            enableEditing: (row) =>
              currentUser
                ? row.original?.task_members?.includes(currentUser)
                : false,
          },
          {
            accessorKey: 'docLink',
            header: 'Link',
            size: 60, // Reduced width
            Cell: ({ cell }) => (
              <a
                href={cell.getValue()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            ),
          },
          {
            accessorKey: 'progress',
            header: 'Progress',
            size: 10, // Reduced width
            Cell: ({ cell, row }) => {
              const timesheets = row.original?.timesheets || [];
              let progress = timesheets.reduce(
                (acc, t) => acc + t?.progress,
                0,
              );
              return `${progress}%`;
            },
          },
        ],
      },
      {
        accessorKey: 'responsible',
        header: 'R',
        enableColumnActions: false,
        enableSorting: false,
        size: 70, // Reduced width
        muiEditTextFieldProps: () => ({
          children: allUsers?.map((user) => (
            <MenuItem key={user.email} value={user.email}>
              {user.email}
            </MenuItem>
          )),
          select: true,
        }),
      },
      {
        accessorKey: 'accountables',
        header: 'A',
        enableEditing: false,
        size: 60, // Reduced width
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick('acc', row)}>
            <FormatListBulletedIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'consulted',
        header: 'C',
        size: 60, // Reduced width
        enableEditing: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick('cons', row)}>
            <FormatListBulletedIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'informed',
        header: 'I',
        enableEditing: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 60, // Reduced width
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick('inf', row)}>
            <FormatListBulletedIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'mail',
        header: 'Mail',
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        size: 60, // Reduced width
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick('mail', row)}>
            <AttachEmailIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'chat',
        header: 'Chat',
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        size: 60, // Reduced width
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick('comments', row)}>
            <ChatIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: 'timesheet',
        header: 'Timesheet',
        enableEditing: false,
        size: 70, // Reduced width
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick('timesheet', row)}>
            <PostAddIcon />
          </IconButton>
        ),
      },
      {
        id: 'versions',
        header: 'Versions',
        size: 200, // Reduced width
        Cell: ({ row }) => (
          <Collapse in={expandedRows[row.index]} timeout="auto" unmountOnExit>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={openVersionNewModal.bind(this, row.original.id)}
              >
                Add Version
              </Button>
              {row.original.versions?.map((rev, index) => (
                <Box key={index} sx={{ margin: '1rem 0' }}>
                  <Typography>
                    <strong>Version:</strong> {rev.version}
                  </Typography>
                  <Typography>
                    <strong>Date:</strong> {rev.date}
                  </Typography>
                  <Typography>
                    <strong>Changes:</strong> {rev.changes}
                  </Typography>
                  <Typography>
                    <a href={rev.link}>Link</a>
                  </Typography>
                </Box>
              ))}
            </Box>
          </Collapse>
        ),
      },
    ],
    [expandedRows, currentUser, allUsers, handleButtonClick],
  );

  const table = useMaterialReactTable({
    columns,
    data: racis,
    // enableColumnOrdering: true,
    // enableRowSelection: true,
    paginationDisplayMode: 'pages',
    renderTopToolbarCustomActions: () => {
      return (
        <Button onClick={openNewRaciModal} variant="contained">
          Add Document
        </Button>
      );
    },
  });

  return <MaterialReactTable table={table} />;
};

export default RACIColumns;
