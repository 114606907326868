import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import React from 'react';
import { DrawingBomContext } from '../../DrawingBom.context';
import { groupingTableCols, selectedTaggedMaterialsCols } from './cols';
import { lightTable } from '../../../../../common/styles/muiProps';

export default function GroupingTable() {
  const {
    consolidatedMaterials,
    consolidatedMaterialsData,
    consolidatedTaggedMaterialsData,
  } = React.useContext(DrawingBomContext);

  const isTaggedMaterials = consolidatedMaterials?.[0].tagNumber;

  const table = useMaterialReactTable({
    columns: isTaggedMaterials
      ? selectedTaggedMaterialsCols
      : groupingTableCols,
    data: isTaggedMaterials
      ? consolidatedTaggedMaterialsData
      : consolidatedMaterialsData,
    enableStickyHeader: true,
    ...lightTable,

    muiTableContainerProps: {
      sx: { maxHeight: isTaggedMaterials ? '450px' : '250px' },
    },
  });

  return <MaterialReactTable table={table} />;
}
