import React from "react";
import { DrawingBomProvider } from "./DrawingBom.context";
import Main from "./Main";

export default function DrawingBomTab() {
  return (
    <DrawingBomProvider>
      <Main />
    </DrawingBomProvider>
  );
}
