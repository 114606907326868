import { Button, Typography } from "@mui/material";

export const mainMaterialsTablesCols = [
  { accessorKey: "markNo", header: "Mark", size: 50 },
  {
    accessorKey: "quantity",
    header: "Qty",
    enableEditing: false,
    size: 50,
  },
  { accessorKey: "totalWtInKgs", header: "Total Wt", size: 50 },
  { accessorKey: "material", header: "Material", size: 70 },
  { accessorKey: "materialCode", header: "Material Code", size: 70 },
  { accessorKey: "description", header: "Description", size: 150 },
  {
    accessorKey: "mrqNumber",
    header: "Mrq #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "poNumber",
    header: "PO #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "mrvNumber",
    header: "MRV #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "srvNumber",
    header: "SRV #",
    enableEditing: false,
    size: 150,
  },
  {
    accessorKey: "heatNumber",
    header: "Heat #",
    enableEditing: false,
    size: 150,
  },

  {
    accessorKey: "actions",
    header: "Actions",
    enableEditing: false,
    size: 90,
  },
];
export const designCols = [
  {
    accessorKey: "drawingBom",
    header: "Drawing Bom (Design)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "name",
        header: "Drawing #",
        size: 90,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "sheetNumber",
        header: "Sheet",
        size: 90,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "revision",
        header: "Rev.",
        size: 90,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      // {
      //   accessorKey: "designQty",
      //   header: "Qty",
      //   size: 20,
      //   muiTableHeadCellProps: {
      //     align: "center",
      //   },
      // },
    ],
    // Footer: ({ table }) => {
    //   const totalHours = table.getRowModel().rows.reduce((total, row) => {
    //     return total + parseFloat(row.original.qty || 0);
    //   }, 0);

    //   return (
    //     <Typography
    //       color=""
    //       sx={{
    //         pl: 1,
    //         fontWeight: "bold",
    //       }}
    //     >
    //       Total Qty = 14
    //     </Typography>
    //     // <Typography>Hours: {totalHours}</Typography>
    //   );
    // },
  },
];
export const SubMaterialsTableCols = [
  {
    accessorKey: "date",
    header: "Date",
    size: 70,
    muiTableHeadCellProps: {
      align: "center",
    },
  },

  {
    accessorKey: "markNo",
    header: "MRQ Bom (Engineering)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "mrqNumber",
        header: "MRQ #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "mrqRev",
        header: "Rev.",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "procureQty",
        header: "Procure Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],

    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography color="" sx={{ pl: 1, fontWeight: "bold" }}>
          Current Procure Qty = 14
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "markNo",
    header: "PO Bom (Procurement)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "poNumber",
        header: "PO #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "poRev",
        header: "Rev.",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "poQty",
        header: "PO Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],
    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography
          color=""
          sx={{
            pl: 1,
            fontWeight: "bold",
          }}
        >
          PO Qty = 14
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "markNo",
    header: "MRV Bom (Vendor)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "mrvNumber",
        header: "MRV #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "mrvQty",
        header: "MRV Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],

    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography
          color=""
          sx={{
            pl: 1,
            fontWeight: "bold",
          }}
        >
          MRVs Qty = 14
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "markNo",
    header: "SRV Bom (Inventory)",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "srvNumber",
        header: "SRV #",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "srvQty",
        header: "SRV Qty",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],

    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography
          color=""
          sx={{
            pl: 1,
            fontWeight: "bold",
          }}
        >
          SRVs Qty = 14
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
  {
    accessorKey: "Status",
    header: "Status",
    size: 250,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "remainingNumber",
        header: "Remaining",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
      },

      {
        accessorKey: "btn",
        header: "Reconcile",
        size: 50,
        muiTableHeadCellProps: {
          align: "center",
        },
        Cell: ({ row }) => {
          return (
            row.original.remainingNumber && (
              <Button variant="outlined">Reconcile</Button>
            )
          );
        },
      },
    ],
    Footer: ({ table }) => {
      const totalHours = table.getRowModel().rows.reduce((total, row) => {
        return total + parseFloat(row.original.qty || 0);
      }, 0);

      return (
        <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>
          Remaining : 0
        </Typography>
        // <Typography>Hours: {totalHours}</Typography>
      );
    },
  },
];
