import { Button, Radio, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { MCSContext } from "../../../MCS.context";
import { getCols } from "../getCols";
import { onClickCell } from "./onClickCell";
import ChatIcon from "@mui/icons-material/Chat";
import { BidChatContext } from "../../BidChat.context";

export default function BidTableBody() {
  const { BidTableRows, setBidTableRows, mrqData, selectedBidVendors } =
    React.useContext(MCSContext);

  const { setOpenChatVendorIndex } = React.useContext(BidChatContext);

  const openChatWithVendor = (vendorName) => {
    const vendors = mrqData?.vendors || [];
    const selected = vendors.findIndex((v) => v.name === vendorName);
    setOpenChatVendorIndex(selected);
  };
  return (
    <TableBody>
      {BidTableRows?.map((row, index) => {
        return (
          <TableRow role="checkbox" tabIndex={-1} key={index}>
            {getCols(mrqData, selectedBidVendors)?.map((col) => {
              const value = row[col.id];
              return (
                <TableCell
                  key={col.id}
                  align={col.align}
                  sx={{
                    alignItems: col.isVendor && "center",
                    backgroundColor:
                      col.id === row?.selected?.name && "#f2f2f2",
                    padding: 1,
                    ":hover": col?.isVendor && {
                      backgroundColor: "#f2f2f2",
                      cursor: "pointer",
                    },
                    "& .hidden-button": {
                      opacity: col?.id === row?.selected?.name ? 1 : 0,
                    },
                    "&:hover .hidden-button": {
                      opacity: 1,
                    },
                  }}
                  onClick={() => {
                    onClickCell({
                      row,
                      col,
                      BidTableRows,
                      setBidTableRows,
                      mrqData,
                      selectedBidVendors,
                    });
                  }}
                >
                  {col.isVendor ? (
                    <div>
                      {col.id === "selected" && (
                        <div
                          style={{
                            display: "inline-block",
                            width: "33%",
                            fontWeight: 400,
                            borderRight: "1px solid #333",
                          }}
                        >
                          {value?.name ?? "-"}
                        </div>
                      )}
                      <div
                        style={{
                          display: "inline-block",
                          width: col.id === "selected" ? "33%" : "40%",
                          borderRight: "1px solid #333",
                          height: "100%",
                        }}
                      >
                        {value?.time ?? "-"}
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: col.id === "selected" ? "33%" : "40%",
                        }}
                      >
                        {value?.cost ?? "-"}
                      </div>
                      <span
                        style={{
                          display: "inline-block",
                          width: "20%",
                        }}
                      >
                        <div className="hidden-button">
                          {col.isVendor && col.id !== "selected" && (
                            <Radio
                              checked={col.id === row?.selected?.name}
                              value="b"
                              disabled={
                                !row[col.id]?.time && !row[col.id]?.cost
                              }
                              name={col.id + row.id}
                              inputProps={{ "aria-label": col.id }}
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                },
                              }}
                            />
                          )}
                        </div>
                      </span>
                    </div>
                  ) : col.id === "id" ? (
                    index + 1
                  ) : (
                    value
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
      <TableRow role="checkbox" tabIndex={-1}>
        {getCols(mrqData, selectedBidVendors)?.map((col) => {
          return (
            <TableCell
              key={col.id}
              align={col.align}
              sx={{
                alignItems: "center",
                padding: 1,
              }}
            >
              {col.isVendor && col.id !== "selected" && (
                <div>
                  <Button
                    onClick={() => openChatWithVendor(col.id)}
                    variant="contained"
                    endIcon={<ChatIcon />}
                  >
                    Chat
                  </Button>
                </div>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableBody>
  );
}
