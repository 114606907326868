import { useParams } from 'react-router-dom';
import axios from '../../../../../common/api/axios';
import RACI from './raci.interface';
import User from '../../../../UsersPage/interface/userInterface';

export const getProjectRACI = async (projectId: string): Promise<RACI[]> => {
  const response: RACI[] = (await axios.get(`/raci/project/${projectId}`)).data;
  console.log('raci', response);
  return response;
};

export const getSharepointDocs = async () => {
  //to do
};

export const createRaci = async (
  parentId: string,
  raci: RACI,
): Promise<void> => {
  console.log('creating raci ', raci, parentId);
  const raciDto = {
    document: raci.document,
    docLin: raci.docLin,
    revNum: raci.revNum,
    responsible: raci.responsible,
  };
  await axios.post(`/raci/${parentId}`, raciDto);
};

export const updateRaci = async (raci: RACI): Promise<void> => {
  console.log('updaaating', raci);
  const updateRaciReq = {
    document: raci.document,
    docLin: raci.docLin,
    revNum: raci.revNum,
    responsible: raci.responsible,
  };
  //to do
  // await axios.put(`/raci/${}`, updateRaciReq);
};

export const deleteRaci = async (raciId: string): Promise<void> => {
  // await axios.delete(`/raci/${raciId}`);
};
export const addUsersList = async (
  racId: string,
  type: string,
  eamils: string[],
) => {
  // console.log('eamails', type);

  await axios.post(`/raci/add-users/${racId}/${type}`, eamils);
};
export const getUsersList = async (
  racId: string,
  type: string,
): Promise<User[] | []> => {
  const users: User[] = (await axios.get(`/raci/get-users/${racId}/${type}`))
    .data;

  return users;
};
