import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import secondAxios from '../../../../../../common/api/axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const NewVersionModal = ({ open, handleClose, handleAddVersion, raciId }) => {
  const [formData, setFormData] = useState({
    version: '',
    date: '',
    changes: '',
    link: '',
    file: null,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Snackbar severity (success or error)
  const [openSnackbar, setOpenSnackbar] = useState(false); // Control Snackbar visibility

  useEffect(() => {
    setFormData({
      version: '',
      date: '',
      changes: '',
      link: '',
      file: null,
    });
    setFileUploaded(false);
    setSnackbarMessage('');
    setOpenSnackbar(false);
  }, [open]);

  // File upload to SharePoint raci
  const uploadFileToSharePointVersion = async (file) => {
    console.log('fileeee', file);

    setIsUploading(true);
    setSnackbarMessage('');
    try {
      const res = await secondAxios.post(
        `/sharepoint/upload/version`,
        { file },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      console.log('Upload response', res);
      setFormData((prev) => ({
        ...prev,
        link: `https://chinookdt189.sharepoint.com/sites/CWT/Shared%20Documents/1. Project%202367%2F1%2E%20Engg%2FPnID%202367%2DRO%2D110%2FREV%2DA%2FDrawing/Versions/${file.name}`,
      }));
      setFileUploaded(true);
      setSnackbarMessage('File uploaded successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error during file upload:', error);
      setSnackbarMessage('Error uploading file. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setIsUploading(false);
      setOpenSnackbar(true);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setFormData((prev) => ({
        ...prev,
        file: file,
      }));
      uploadFileToSharePointVersion(file);
    } else {
      setSnackbarMessage('Please upload a valid PDF file.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    if (!fileUploaded) {
      setSnackbarMessage('You must upload a file before submitting.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    handleAddVersion(formData, raciId); // Send the data back to parent component
    setSnackbarMessage('Version added successfully!');
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" gutterBottom>
          Add New Version
        </Typography>

        {/* Version */}
        <TextField
          fullWidth
          label="Version"
          name="version"
          value={formData.version}
          onChange={(e) =>
            setFormData({ ...formData, version: e.target.value })
          }
          margin="normal"
        />

        {/* Date */}
        <TextField
          fullWidth
          label="Date"
          name="date"
          type="date"
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        {/* Changes */}
        <TextField
          fullWidth
          label="Changes"
          name="changes"
          value={formData.changes}
          onChange={(e) =>
            setFormData({ ...formData, changes: e.target.value })
          }
          margin="normal"
          multiline
          rows={3}
        />

        {/* Upload PDF Button */}
        <Box sx={{ mt: 2 }}>
          <input
            accept="application/pdf"
            style={{ display: 'none' }}
            id="upload-pdf-version"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="upload-pdf-version">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadFileIcon />}
            >
              Upload PDF
            </Button>
          </label>
          {formData.file && (
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              {`Uploaded File: ${formData.file.name}`}
            </Typography>
          )}
        </Box>

        {isUploading && <CircularProgress size={24} sx={{ mt: 2 }} />}

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          disabled={!fileUploaded || isUploading}
        >
          Add Version
        </Button>

        {/* Snackbar for messages */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default NewVersionModal;
