import { Box } from '@mui/material'
import React from 'react'
import HeadingComponent from '../../../common/components/HeadingComponent'
import TasklistTab from '../SingleProjectPage/tabs/PunchlistTab'

const ProjectTaskPage = () => {
    return (
        <Box padding={"2rem"}>
            <HeadingComponent heading='Tasks' />
            <TasklistTab />
        </Box>
    )
}

export default ProjectTaskPage