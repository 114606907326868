import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import User from '../../../../../../../UsersPage/interface/userInterface';

interface UserListProps {
  users: User[] | [];
  removeUser: (index: number) => void;
}

const UserList: React.FC<UserListProps> = ({ users, removeUser }) => {
  return (
    users && (
      <List>
        {users.map((user, index) => (
          <ListItem key={index}>
            <ListItemText primary={user.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => removeUser(index)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    )
  );
};

export default UserList;
