import { Badge, Box, Button, Chip, IconButton, MenuItem } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { MRT_ColumnDef } from 'material-react-table';
import Task from './raci.interface';
import ProjectUser from '../../../interfaces/projectUserInterface';
import User from '../../../../UsersPage/interface/userInterface';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import PreviewIcon from '@mui/icons-material/Preview';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Form } from 'react-router-dom';
import RACI from './raci.interface';
import { SharepointDcument } from '.';
// import { CircularProgressWithLabel } from "../../../../../components/widgets/CircularProgressWithLabel";

interface RACIColumnsProps {
  allUsers: User[] | [];
  handleButtonClick: (type: string, row: any) => void; // Assuming you have a function signature here
  currentUser: string;
  sharepointDocuments: SharepointDcument[];
}

export const initialDialogState = {
  mail: false,
  userDialog: false,
  timesheet: false,
  preview: false,
  comments: false,
};

export const RACIColumns = ({
  handleButtonClick,
  currentUser,
  allUsers,
  sharepointDocuments,
}: RACIColumnsProps): MRT_ColumnDef<RACI>[] => [
  {
    accessorKey: 'document',
    header: `Document`,
    enableEditing: true,
    Cell: ({ cell, row }: any) => {
      // console.log('doc cell', cell.getValue());
      return cell.getValue();
    },
    muiEditTextFieldProps: () => ({
      children: [
        <MenuItem key={1} value={'2D'}>
          2D
        </MenuItem>,
        <MenuItem key={1} value={'3D'}>
          3D
        </MenuItem>,
      ],
      select: true,
    }),
  },
  {
    accessorKey: 'revNum',
    header: `Rev #`,
    enableEditing: (row: any) => {
      if (row.original.task === '') return true;
      else if (currentUser)
        return row?.original?.task_members?.includes(currentUser);
      else return false;
    },
    size: 40,
  },
  {
    accessorKey: 'docLin',
    header: `Doc Link`,
    size: 100,
    Cell: ({ cell }: any) => {
      const docLink = cell.getValue();
      return (
        <a href={docLink} target="_blank" rel="noopener noreferrer">
          {docLink}
        </a>
      );
    },
  },
  {
    accessorKey: 'responsible',
    header: 'R',
    size: 90,
    muiEditTextFieldProps: () => ({
      children: allUsers.map((user: User) => (
        <MenuItem key={user.email} value={user.email}>
          {user.email}
        </MenuItem>
      )),
      select: true,
    }),
    Cell: ({ cell, row }: any) => {
      // console.log('cell', cell.getValue());

      return cell.getValue();
    },
  },
  {
    accessorKey: 'accountables',
    header: `A`,
    enableEditing: false,
    size: 100,
    enableColumnActions: false,
    Cell: ({ row }: any) => (
      <IconButton
        onClick={() => handleButtonClick('acc', row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <FormatListBulletedIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: 'consulted',
    header: `C`,
    size: 100,
    enableEditing: false,
    enableColumnActions: false,
    Cell: ({ row }: any) => (
      <IconButton
        onClick={() => handleButtonClick('cons', row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <FormatListBulletedIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: 'informed',
    header: 'I',
    enableEditing: false,
    enableColumnActions: false,
    size: 80,
    Cell: ({ row }: any) => (
      <IconButton
        onClick={() => handleButtonClick('inf', row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <FormatListBulletedIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: 'mail',
    header: 'Mail',
    enableEditing: false,
    enableColumnActions: false,
    size: 40,
    Header: () => <AttachEmailIcon />,
    Cell: ({ row }: any) => (
      <IconButton
        onClick={() => handleButtonClick('mail', row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <AttachEmailIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: 'prev',
    header: 'prev',
    enableEditing: false,
    enableColumnActions: false,
    size: 40,
    Header: () => <PreviewIcon />,
    Cell: ({ row }: any) => (
      <IconButton
        onClick={() => handleButtonClick('preview', row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <PreviewIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: 'comments',
    header: 'comments',
    enableEditing: false,
    enableColumnActions: false,
    size: 40,
    Header: () => <ChatIcon />,
    Cell: ({ row }: any) => (
      <IconButton
        onClick={() => handleButtonClick('comments', row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <ChatIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: 'timesheet',
    header: `Timesheet`,
    enableEditing: false,
    enableSorting: false,
    enableColumnActions: false,
    size: 20,
    Header: () => <PostAddIcon />,
    Cell: ({ row }: any) => (
      <IconButton
        onClick={() => handleButtonClick('timesheet', row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        <PostAddIcon />
      </IconButton>
    ),
  },
];
