import { Box, Paper } from '@mui/material';
import React from 'react';
import SharepointTab from './SharepointTab';
import NavTabs from '../../../../../common/components/tabs/NavTabs';
import { useParams } from 'react-router-dom';
import DocReviewer from './DocReviewer';

const DCSTab = (props) => {
  const { projectId } = useParams();
  // console.log('iddddddd', { projectId });
  const tabs = [
    {
      label: 'Sharepoint ',
      panel: <SharepointTab />,
    },
    // {
    //   label: "MRQ",
    //   panel: <DCSTable projectId={projectId || ""} type="MRQ" />,
    // },
    {
      label: 'Doc. Reviewer',
      panel: <DocReviewer projectId={projectId} />,
    },
    // {
    //   label: "Doc. Transmittal",
    //   panel: (
    //     <DocumentTransmittalTab
    //       projectId={projectId || ""}
    //       type="Doc. Transmittal"
    //     />
    //   ),
    // },
    // {
    //   label: "RFI",
    //   panel: <DCSTable type="RFI" />,
    // },
    // {
    //   label: "Change Order",
    //   panel: <ChangeOrder type="Change Order" />,
    // },
    // {
    //   label: "Transmittal",
    //   panel: <DocumentTransmittalTab />,
    // },
  ];

  return (
    <Paper sx={{ width: '100%', display: 'flex' }}>
      <NavTabs tabs={tabs} navLabel="dcs-tab" />
    </Paper>
  );
};

export default DCSTab;
