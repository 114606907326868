const steelDensity = 7.85;

export const plateSizeOptions = [
  { value: "6", size: ["2 x 6"] },
  { value: "8", size: ["2 x 6", "2.5 x 8"] },
  { value: "10", size: ["2 x 6", "2.5 x 8", "3 x 10"] },
  { value: "12", size: ["2 x 6", "2.5 x 8", "3 x 10", "3 x 12"] },
  { value: "16", size: ["2 x 6", "2.5 x 8", "3 x 10", "3 x 12"] },
];

export const requirementsOptions = [
  { req: "Nil", value: "NO" },
  { req: "Galvanised", value: "G1" },
  { req: "NACE MR 0175", value: "N1" },
  { req: "Carbon Equ. 0.43", value: "E1" },
  { req: "Cadmium Plated", value: "C1" },
];

export const materialsOptions = [
  { material: "SA 516 70", value: "PA" },
  { material: "SA 516 60", value: "PB" },
  { material: "SA 36", value: "FA" },
  { material: "SA 240 304", value: "FB" },
  { material: "SA 240 304L", value: "WA" },
  { material: "SA 240 316", value: "WB" },
  { material: "SA 240 316L", value: "VA" },
  { material: "SA 106 B", value: "WC" },
  { material: "SA 333 6", value: "XA" },
  { material: "SA 312 TP304", value: "GF" },
  { material: "SA 312 TP316", value: "AA" },
  { material: "SA 312 TP316L", value: "AB" },
  { material: "SA 105", value: "AC" },
  { material: "A 36", value: "FA" },
  { material: "SA 105 N", value: "FA" },
  { material: "SA 106 Gr.B", value: "FA" },
];

const sizeWithMeter = {
  "2 x 6": "2 m x 6 m",
  "2.5 x 8": "2.5 m x 8 m",
  "3 x 10": "3 m x 10 m",
  "3 x 12": "3 m x 12 m",
};

const sizeInMaterialCode = {
  "2 x 6": "0206",
  "2.5 x 8": "2508",
  "3 x 10": "0310",
  "3 x 12": "0312",
};

const sizeForWeight = {
  "2 x 6": 2 * 6,
  "2.5 x 8": 2.5 * 8,
  "3 x 10": 3 * 10,
  "3 x 12": 3 * 12,
};

export const generatingProcuredData = (generatingData) => {
  return generatingData.map((m) => {
    const description =
      "PL, " +
      sizeWithMeter[m.size] +
      " x " +
      m.thickness +
      " mm, " +
      m.materialNo;
    /////////////////////////////////////////////

    const materialCode =
      "PL" +
      sizeInMaterialCode[m.size] +
      "0" +
      m.thickness +
      materialsOptions.find((opt) => opt.material === m.materialNo).value +
      requirementsOptions.find((opt) => opt.req === m.requirements).value;
    /////////////////////////////////////////////

    const totalWeight = Math.round(
      sizeForWeight[m.size] * Number(m.thickness) * steelDensity * m.quantity
    );
    /////////////////////////////////////////////

    const actualDiff = totalWeight / Number(m.actualTotalWeight);
    const diff = Math.round(actualDiff * 100) / 100;

    return { ...m, description, materialCode, totalWeight, diff };
  });
};
