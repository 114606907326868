export const lightTable = {
    enableColumnActions: false,
    enableColumnFilters: false,
    enableTopToolbar:false,
    // enableBottomToolbar:false,
    enablePagination: false,
    enableSorting: false,
    mrtTheme: (theme: any) => ({
        // baseBackgroundColor:"transparent"
        // baseBackgroundColor: theme.palette.background.default, //change default background color
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
        sx: {
            // border: '1px solid rgba(81, 81, 81, .5)',
            // caption: {
            //     captionSide: 'top',
            // },
        },
    },
    muiTableHeadCellProps: {
        sx: {
            border: '0.5px solid rgba(81, 81, 81, .2)',
            // fontStyle: 'italic',
            fontWeight: 'normal',
        },
    },
    muiTableBodyCellProps: {
        sx: {
            border: '1px solid rgba(81, 81, 81, .2)',
        },
    },
}