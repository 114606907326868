import { MRT_Row, useMaterialReactTable } from 'material-react-table';
import React from 'react';
import Project from '../interfaces/projectInterface';
import projectsColumns from '../utils';
import { EditableTable } from '../../../common/components/table/EditableTable';
import {
  createProject,
  deleteProject,
  getProjects,
  updateProject,
} from '../api';
import ProjectUser from '../interfaces/projectUserInterface';
import { paths } from '../projectsRoutes';
import { useNavigate } from 'react-router-dom';
import User from '../../UsersPage/interface/userInterface';

const initialData: Project = {
  name: '',
  users: [],
  progress: 0,
};

const ProjectTable = ({
  users,
  openViewerModal,
}: {
  users: User[];
  openViewerModal: (row: MRT_Row<Project>) => void;
}) => {
  const navigate = useNavigate();
  const navigateToTaskList = (row: string) => {
    // get id of project here
    const id = row;
    navigate(`${paths.myProjects}/${id}`);
  };

  const additionalProps = {
    muiTableBodyRowProps: ({ row }: { row: MRT_Row<Project> }) => ({
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.target as Element;
        if (!target.closest('.MuiButtonBase-root') && !target.closest('a')) {
          // navigateToTaskList(row.id);
        }
      },
      sx: {
        // cursor: 'pointer', //change the cursor when adding an onClick
      },
    }),
    defaultColumn: {
      minSize: 20,
    },
  };

  const gotToMcs = (project: any) => {
    navigate('/mcs/' + project?.id);
  };

  // const users: ProjectUser[] = [{ id: "adgsdgwr", name: "John Doe", role: "lead" }]
  // const users = ["John Doe", "Jill SMith", "waagas"]
  return (
    <EditableTable<Project>
      name="Project"
      columns={projectsColumns(users, openViewerModal, gotToMcs)}
      initialData={initialData}
      enableEditing={true}
      enableCreate={true}
      displayMode="modal"
      api={{
        getItems: getProjects,
        createItem: createProject,
        updateItem: updateProject,
        deleteItem: deleteProject,
      }}
      additionalProps={additionalProps}
      parentId="" //TODO: make parentID optional... or company could be parent of project
    />
  );
};

export default ProjectTable;
