import Materials from "../MCSTab/interface/MaterialsInterface"

export const MRQDetailsGrid = [
    {
        label: "Project Name",
        field: "project",
        grid: 0.8
    },
    {
        label: "Made By",
        field: "drawnBy",
        grid: 0.6
    },
    {
        label: "Checked By",
        field: "checkedBy",
        grid: 0.8
    },
    {
        label: "Date",
        field: "date",
        grid: 0.5
    },
    {
        label: "MRQ Number",
        field: "mrqNumber",
        grid: 0.8
    },
    {
        label: "MRQ Rev",
        field: "rev",
        grid: 0.6
    },
    {
        label: "Title",
        field: "title",
        grid: 0.8
    },
    {
        label: "From",
        field: "from",
        grid: 0.8
    },
    {
        label: "Rev",
        field: "rev",
        grid: 0.6
    },
    {
        label: "Sheet No.",
        field: "sheetNumber",
        grid: 0.8
    },
    // {
    //     label: "Rev",
    //     field: "rev",
    //     grid: 0.8
    // },
    // {
    //     label: "Sheet No.",
    //     field: "sheet",
    //     grid: 0.6
    // },

]

// interface mrqMaterialsTableProps {
//     setEditedMaterials: React.Dispatch<React.SetStateAction<Record<string, Materials>>>
// }

export const mrqMaterialsColumns = [
    {
        accessorKey: 'id',
        header: 'ID',
        enableEditing: false,
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 50, //small column
    },
    {
        accessorKey: 'tagNumber',
        header: 'Tag Number',
        enableEditing: false,
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 50, //small column
        Cell: ({ cell }: any) => {
            console.log(cell.getValue(), "CELLL") //returns undefined is no tag numbers
            return (
                <div>
                    {cell.getValue()?.[0] !== undefined ?
                        cell.getValue().join(', ')
                        : ""
                    }
                </div>
            )
        }
    },
    {
        accessorKey: 'materialCode',
        header: 'Material Code',
        enableEditing: false,
        // grow: false, //don't allow this column to grow to fill in remaining space
        size: 120, //small column
    },
    {
        accessorKey: 'description',
        header: 'Material Description',
        enableEditing: false,
        // grow: false, //don't allow this column to grow to fill in remaining space
        size: 200, //small column
    },
    {
        accessorKey: 'quantity',
        header: 'Quantity',
        enableEditing: false,
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 50, //small column
    },
    {
        accessorKey: 'unit',
        header: 'Unit',
        enableEditing: false,
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 50, //small column
    },
    // {
    //     accessorKey: 'mrpNumber',
    //     header: 'MRP #',
    //     grow: false, //don't allow this column to grow to fill in remaining space
    //     size: 100, //small column
    // },
    {
        accessorKey: 'poNumber',
        header: 'PO #',
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column

    },
    {
        accessorKey: 'poDate',
        header: 'PO Date',
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column
    },
    {
        accessorKey: 'mrvNumber',
        header: 'MRV #',
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column

    },
    {
        accessorKey: 'mrvDate',
        header: 'MRV Date',
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column
    },
    {
        accessorKey: 'srvNumber',
        header: 'SRV #',
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column

    },
    {
        accessorKey: 'srvDate',
        header: 'SRV Date',
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column
    },
]