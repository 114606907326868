import { MaterialReactTable } from "material-react-table";
// import LabelledBox from '../../../../../../../common/components/LabelledBox'
import { Box } from "@mui/material";
import ActionBtns from "./ActionBtns";
import { lightTable } from "../../../../../../common/style/mrtProps";
import LabelledBox from "../../../../../../common/components/LabelledBox";
import { mrqMaterialsColumns } from "../utils";
import { useContext } from "react";
import { MCSContext } from "../MCS.context";
// import AutoCompleteCreatable from '../../../../../../../common/components/AutoCompleteCreatable'

const MrqMaterialsTable2 = () => {

  const { mrqData } = useContext(MCSContext)

  console.log("MRQQQ", mrqData)
  const groupedData = mrqData ?
    mrqData?.mrqMaterials?.reduce((acc, mrqMaterial) => {
      const materialCode = mrqMaterial?.material?.taggedMaterial?.materialCode;
      const tagNumber = mrqMaterial?.material?.taggedMaterial?.tagNumber;
      const poNumber = mrqMaterial?.po?.number;
      const description = mrqMaterial?.material?.bulkMaterial?.description;
      const poDate = mrqMaterial?.po?.date;
      const mrvNumber = mrqMaterial?.mrv?.number;
      const mrvDate = mrqMaterial?.mrv?.date;
      const srvNumber = mrqMaterial?.srv?.number;
      const srvDate = mrqMaterial?.srv?.date;
      const existingItem = acc[materialCode];
      if (existingItem) {
        existingItem.quantity += 1;
        existingItem.tagNumber.push(tagNumber);
        existingItem.id.push(mrqMaterial.id);
        // existingItem.tagNumber += `, ${tagNumber}`;
        // existingItem.id += `, ${mrqMaterial.id}`;

        // You can add similar checks and concatenations for other fields
      } else {
        acc[materialCode] = {
          id: [mrqMaterial.id],
          tagNumber: [tagNumber] || null,
          materialCode: materialCode || "",
          description: description || "",
          quantity: 1,
          brand: "",
          part_num: "",
          md: "",
          poNumber,
          poDate,
          mrvNumber,
          mrvDate,
          srvNumber,
          srvDate,
          // You can initialize other fields here
        };
      }
      return acc;
    }, {}) : {
    }

  const formattedData = Object.values(groupedData);

  // console.log({ formattedData })
  const handleSaveItem = async (values) => {
    // console.log(values)
  };

  return (
    <LabelledBox label="Mrq Materials">
      <MaterialReactTable
        data={formattedData}
        enableRowSelection={true}
        columns={mrqMaterialsColumns}
        positionActionsColumn="last"
        onEditingRowSave={handleSaveItem}
        renderTopToolbarCustomActions={({ table }) => {
          const selectedRows = table.getSelectedRowModel();

          // console.log((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()), hasPo(), hasMrv(), false && false)
          return (
            <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
              <ActionBtns selectedRows={selectedRows} />
            </Box>
          );
        }}
        {...lightTable}
        enableTopToolbar={true} //overriding lightTable
        initialState={{
          columnVisibility: { id: false },
        }}
      />
      {/* <Button variant='contained' sx={{ mt: 1 }}>Save</Button> */}
    </LabelledBox>
  );
};

export default MrqMaterialsTable2;
