import axios from '../../../common/api/axios';

export const getDrawings = async (equipId) => {
  const response = await axios.get(`/drawing/project/` + equipId);
  // console.log({ response });
  return response.data;
};

export const getDrawingData = async (drawingId) => {
  const response = await axios.get(`/drawing/` + drawingId);
  // console.log({ response });
  return response.data;
};
