import { Typography } from '@mui/material';
// import NavBar from '../../common/components/layout/NavBar';

export default function ErrorPage() {
  return (
    <>
      {/* <NavBar /> */}
      <Typography>An Error Occured</Typography>
      <Typography>Could not find this page</Typography>
    </>
  );
}
