import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import User from '../../../../../../../UsersPage/interface/userInterface';

interface AddUserFormProps {
  addUser: (user: User) => void;
  allUsers: User[];
}

const AddUserForm: React.FC<AddUserFormProps> = ({ addUser, allUsers }) => {
  const [selectedUser, setSelectedUser] = useState<string>('');

  const handleChange = (event: SelectChangeEvent<any>) => {
    setSelectedUser(event.target.value as string);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedUser.trim() !== '') {
      const user: User = allUsers.filter(
        (user) => user.email === selectedUser,
      )[0];

      addUser(user);
      setSelectedUser('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel id="user-select-label">Add</InputLabel>
            <Select
              labelId="user-select-label"
              id="user-select"
              value={selectedUser}
              onChange={handleChange}
              fullWidth
            >
              {allUsers.map((user) => (
                <MenuItem value={user.email}>{user.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button type="submit" variant="contained" color="primary">
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddUserForm;
