import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import React from 'react';
import { bulkedMaterialsCols } from './cols';
import { lightTable } from '../../../../../common/styles/muiProps';
import { DrawingBomContext } from '../../DrawingBom.context';

export default function MainSelectedMaterialsTable() {
  const { consolidatedMaterials } = React.useContext(DrawingBomContext);

  const table = useMaterialReactTable({
    columns: bulkedMaterialsCols,
    data: consolidatedMaterials,
    ...lightTable,

    muiTableContainerProps: { sx: { maxHeight: '350px' } },
  });

  return <MaterialReactTable table={table} />;
}
