import { Stack } from '@mui/material'
import React from 'react'

const HeadingComponent = ({ heading }: { heading: string }) => {
    return (
        <Stack
            sx={{ paddingBottom: '2rem' }}
            direction={'row'}
            justifyContent="space-between"
            height={'3rem'}
            alignItems="center"
        >
            <h2>{heading}</h2>
        </Stack>
    )
}

export default HeadingComponent