export const getBidTableRows = ({ mrqData, bidData, existedPos }) => {
  const result = mrqData?.mrqMaterials?.map((m) => {
    let obj = {};
    let poData = {};

    bidData.forEach((b) => {
      if (b.mrqMaterialId === m.id) {
        obj[b.vendor.name] = {
          vendorId: b.vendorId,
          time: b.time,
          cost: b.cost,
        };
        return obj[b.vendor.name];
      }
    });

    existedPos[0] &&
      existedPos[0].id &&
      existedPos.forEach((po) => {
        po.poMaterials.forEach((poMaterial) => {
          if (m.id === poMaterial.mrqMaterialId) {
            const record = bidData.filter(
              (m) =>
                m.vendor.name === poMaterial.vendor.name &&
                m.mrqMaterialId === poMaterial.mrqMaterialId
            )[0];
            const time = record?.time;
            const cost = record?.cost;

            poData = {
              selected: {
                name: poMaterial.vendor.name,
                time,
                cost,
              },
              po: po.poNumber,
              vendorId: poMaterial.vendor.id,
            };
          }
        });
      });

    const row = {
      mrqMaterialId: m.id,
      materialCode: m.materialCode,
      tagNumber: m.materials[0].taggedMaterial?.tagNumber,
      description: m.description,
      qty: m.quantity,

      ...obj,
      ...poData,
    };

    return row;
  });

  return result && result[0] ? result : [];
};
