import './App.css';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { router } from './routing/router';
import websiteTheme, { darkTheme } from './common/style/theme';
import AuthContextProvider from './common/contexts/authContext';

declare global {
  interface Window {
    onModelSelectedTimeout?: any;
  }
}

function App() {
  return (
    <ThemeProvider theme={websiteTheme}>
      <CssBaseline />
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
