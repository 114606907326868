export const data = [
  {
    date: "2024-07-01",
    mrqNumber: "001",
    mrqRev: "01",
    procureQty: 14,

    poNumber: "001",
    poRev: "01",
    poQty: 14,
    remainingNumber: -14,
  },
  {
    date: "2024-07-14",

    mrvNumber: "001",
    mrvQty: 10,
    remainingNumber: -4,
  },
  {
    date: "2024-07-15",

    poNumber: "002",
    poRev: "01",
    poQty: 4,
    remainingNumber: -4,
  },

  {
    date: "2024-07-19",

    mrvNumber: "002",
    mrvQty: 4,
    remainingNumber: 0,
  },

  // {
  //   date: "2024-07-20",
  //   drawingNumber: "001",
  //   drawingSheet: "1",
  //   drawingRev: "04",
  //   designQty: 20,

  //   mrqNumber: "001",
  //   mrqRev: "02",
  //   procureQty: 17,
  // },
  // {
  //   date: "2024-07-25",

  //   poNumber: "003",
  //   poRev: "01",
  //   poQty: 3,
  //   remainingNumber: -3,
  // },
];

export const designData = [
  {
    date: "2024-07-01",
    drawingNumber: "001",
    drawingSheet: "1",
    drawingRev: "01",
    designQty: 12,
  },
  {
    date: "2024-07-07",
    drawingNumber: "001",
    drawingSheet: "1",
    drawingRev: "02",
    designQty: 15,
  },
  {
    date: "2024-07-11",
    drawingNumber: "001",
    drawingSheet: "1",
    drawingRev: "03",
    designQty: 17,
  },
];
