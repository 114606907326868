import { Box } from '@mui/material'
import React from 'react'
import HeadingComponent from '../../common/components/HeadingComponent'
import MRQTab from '../ProjectsPage/SingleProjectPage/tabs/MRQTab'

const MrqPage = () => {
    return (
        <Box padding={"2rem"}>
            <HeadingComponent heading='Material Requisitions' />
            <MRQTab />
        </Box>
    )
}

export default MrqPage