import React, { useEffect, useState } from 'react'
import Task from '../../taskInterface'
import { Avatar, Box, Button, Dialog, DialogContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Typography } from '@mui/material'
import Comment from './interface'
import { createComment, getComments } from './api'
import { Send as SendIcon } from '@mui/icons-material';


interface CommentsProps {
    open: boolean
    handleClose: (event: MouseEvent) => void
    task?: Task | undefined
}

const CommentsDialog = ({ open, handleClose, task }: CommentsProps) => {

    const [commentsData, setCommentsData] = useState<Comment[]>([])
    const [refresh, setRefresh] = useState<Comment[]>()
    const [newComment, setNewComment] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const comments = await getComments();
                console.log(comments)
                setCommentsData(comments);
            } catch (error) {
                // Handle error
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleAddComment = () => {
        if (newComment.trim() !== '') {
            const newCommentObject = {
                // user: currentUser,
                comment: newComment.trim(),
                taskId: task?.id!,
            };
            const commentObject = {
                comment: newComment.trim(),
                taskId: task?.id!,
            }
            createComment(commentObject)
            // createCommentMutation.mutate(commentObject)
            setCommentsData((prevComments) => [...prevComments, newCommentObject]);
            setNewComment('');
        }
    };



    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogContent>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Comments
                    </Typography>
                    <List>
                        {
                            commentsData?.length ?
                                commentsData?.map((comment, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar>{comment?.user?.name.charAt(0)}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={comment?.user?.name || "Unknown"}
                                                secondary={comment.comment}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))
                                :
                                <>
                                    Add a comment on the task
                                </>
                        }
                    </List>
                    <Box mt={2}>
                        <TextField
                            fullWidth
                            label="Add a comment"
                            variant="outlined"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddComment}
                            endIcon={<SendIcon />}
                            style={{ marginTop: '10px' }}
                        >
                            Add Comment
                        </Button>
                    </Box>
                </Paper>
            </DialogContent >
        </Dialog>
    )
}

export default CommentsDialog