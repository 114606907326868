import axios from "../../../../../../../common/api/axios";
import Timesheet from "../../../../../../TimesheetsPage/interface";

export const getTimesheets = async (taskId: string): Promise<Timesheet[]> => {
  // const response = await axios.get('/timesheet/' + taskId);
  console.log(taskId)
  const response = await axios.get('/task/' + taskId);
  console.log(response.data)
  return response.data?.timesheets;
};

export const createTimesheet = async (taskId: string, timesheet: any): Promise<void> => {
  const postRequestData = {
    ...timesheet,
    hours: parseInt(timesheet.hours) || 0, //TODO: Check why MRT is returning strings though its set to number
    progress: parseInt(timesheet.progress) || 0,
    taskId
  };
  console.log({ postRequestData })
  await axios.post('/timesheet', postRequestData);
};

export const updateTimesheet = async (timesheet: Timesheet): Promise<void> => {
  await axios.put(`/timesheet/${timesheet.id}`, timesheet);
};

export const deleteTimesheet = async (timesheetId: string): Promise<void> => {
  await axios.delete(`/timesheet/${timesheetId}`);
};
