import { Box } from '@mui/material'
import React from 'react'
import HeadingComponent from '../../common/components/HeadingComponent'
import { EditableTable } from '../../common/components/table/EditableTable'
import Timesheet from './interface'
import { timesheetColumns } from './utils'
import { initialData } from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/components/TimesheetDialog.tsx/utils'
import { getAllTimesheets } from './api'
import { createTimesheet, deleteTimesheet, updateTimesheet } from '../ProjectsPage/SingleProjectPage/tabs/PunchlistTab/components/TimesheetDialog.tsx/api'

const TimesheetsPage = () => {

    const additionalProps = {
        enableColumnActions:true,
        enableGrouping:true,
        enableColumnDragging:false
    }

    return (
        <Box padding={"2rem"}>
            <HeadingComponent heading='Timesheets' />
            <EditableTable<Timesheet>
                name="Timesheet"
                displayMode="row"
                columns={timesheetColumns}
                enableEditing={true}
                enableCreate={true}
                initialData={initialData}
                api={{
                    getItems: getAllTimesheets,
                    createItem: createTimesheet,
                    updateItem: updateTimesheet,
                    deleteItem: deleteTimesheet,
                }}
                parentId={""}
                additionalProps={additionalProps}
            />
        </Box>
    )
}

export default TimesheetsPage