export const SPIRGrid = [
    {
        label: "SPIR Number:",
        field: "spirNumber",
        grid: 1.6,
    },
    {
        label: "PO Number:",
        field: "po_number",
        grid: 0.8,
    },
    {
        label: "Vendor name:",
        field: "vendor_name",
        grid: 1.6,
    }
]

export const SPIRColumns = [

    {
        accessorKey: "description",
        header: "Description of parts",
        enableEditing: false,
        // grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column
    },
    {
        accessorKey: "reference_number",
        header: "Reference No.",
        enableEditing: false,
        // grow: false, //don't allow this column to grow to fill in remaining space
        size: 120, //small column
    },
    {
        accessorKey: "part_number",
        header: " Original Part No.",
        // grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column
    },
    {
        accessorKey: "materialSpecification",
        header: "Material Specification",
        // grow: false, //don't allow this column to grow to fill in remaining space
        size: 150, //small column
    },
    {
        accessorKey: "qty",
        header: "Qty",
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column
    },
    {
        accessorKey: "cost",
        header: "Cost",
        grow: false, //don't allow this column to grow to fill in remaining space
        size: 100, //small column
    },

];
