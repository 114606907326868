import { Box, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MrqSelector from './MrqSelector'
import MrqDetails from './MrqDetails';
// import MrqMaterialsTable from './MrqMaterialsTable';
import Materials from '../MCSTab/interface/MaterialsInterface';
import { getMrqData, getMrqs } from './api';
import MRQ from './MrqInterface';
import MrqMaterialsTable2 from './MrqMaterialsTable';
import { MCSContext, MCSContextProvider } from './MCS.context';

const MRQTab = () => {

    const { mrqSelection, mrqData, setMrqData, handleMrqChange, handleRevisionChange } = React.useContext(MCSContext)
    const [mrqList, setMrqList] = React.useState<MRQ[]>([])
    // const [mrqSelection, setMrqSelection] = React.useState({
    //     selectedMrq: "",
    //     revisions: [],
    //     selectedRevision: ""
    // })
    // const [mrqData, setMrqData] = React.useState<MRQ>();

    const [selectedMrq, setSelectedMrq] = React.useState('');
    const [selectedMrqData, setSelectedMrqData] = React.useState()
    const [editedMaterials, setEditedMaterials] = useState<Record<string, Materials>>({});


    // Handler for selecting MRQ
    // const handleMrqChange = (event: SelectChangeEvent<string>) => {
    //     const mrqId = event.target.value as string;
    //     const selectedMrq = mrqList.find(mrq => mrq.id === mrqId);
    //     const revisions = selectedMrq?.revs ? selectedMrq.revs : [];
    //     setMrqSelection({ selectedMrq: mrqId, revisions: revisions, selectedRevision: "" });

    // };

    // Handler for selecting revision
    // const handleRevisionChange = (event: SelectChangeEvent<string>) => {
    //     setMrqSelection({ ...mrqSelection, selectedRevision: event.target.value as string });
    // };

    console.log({ editedMaterials })

    // const handleChange = (event: SelectChangeEvent) => {
    //     setSelectedMrq(event.target.value);
    // };

    useEffect(() => {
        const fetchMrqList = async () => {
            await getMrqs()
                .then(res => setMrqList(res))
        };

        fetchMrqList()
    }, [])

    console.log({ mrqList })

    useEffect(() => {
        if (mrqSelection.selectedMrq && !mrqSelection.selectedRevision) {
            const fetchMrqData = async () => {
                await getMrqData(mrqSelection.selectedMrq)
                    .then(res => setMrqData(res))

            }
            fetchMrqData()
        }
        else if (mrqSelection.selectedRevision) {
            const fetchMrqData = async () => {
                await getMrqData(mrqSelection.selectedRevision)
                    .then(res => setMrqData(res))
            }
            fetchMrqData()
        }

    }, [mrqSelection])

    return (
        <MCSContextProvider>
            <Box>
                <MrqSelector mrqList={mrqList}
                    selectedMrq={mrqSelection.selectedMrq}
                    selectedRevision={mrqSelection.selectedRevision}
                    revisions={mrqSelection.revisions}
                    onMrqChange={handleMrqChange}
                    onRevisionChange={handleRevisionChange} />
                {/* <br /> */}
                <MrqDetails  />
                {/* <br /> */}
                <MrqMaterialsTable2 />
            </Box>
        </MCSContextProvider>
    )
}

export default MRQTab