import { Box, Typography } from "@mui/material";
import React from "react";

export default function SelectedMaterialsBox({ selectedRows }) {
  return (
    <>
      <Typography variant="h6">Selected Materials are :</Typography>
      {selectedRows?.rows?.map((row) => {
        return row.original?.tagNumber?.map((tagNumber) => {
          return (
            <Box
              sx={{
                backgroundColor: "hsla(250, 86%, 97%, 1)",
                mb: "3px",
                p: 1,
                borderRadius: "5px",
              }}
            >
              - {tagNumber} / {row.original.materialCode}
            </Box>
          );
        });
      })}
    </>
  );
}
