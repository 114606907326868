import { Dialog, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { EditableTable } from '../../../../../../../common/components/table/EditableTable'
import Task from '../../taskInterface'
import { initialData, timesheetColumns } from './utils'

import { createTimesheet, deleteTimesheet, getTimesheets, updateTimesheet } from './api'
import { useMsal } from '@azure/msal-react'
import Timesheet from '../../../../../../TimesheetsPage/interface'

interface TimesheetsProps {
    open: boolean
    handleClose: (event: MouseEvent) => void
    task: Task
}

const TimesheetDialog = ({ open, handleClose, task }: TimesheetsProps) => {

    const { instance, accounts } = useMsal();
    const username = accounts[0]?.name || "Me";

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"lg"}>
            <DialogContent>
                <Typography variant="h5" gutterBottom>
                    Timesheets for Task: {task?.task || ""}
                </Typography>
                <EditableTable<Timesheet>
                    name="Timesheet"
                    displayMode="row"
                    columns={timesheetColumns({ username })}
                    enableEditing={true}
                    enableCreate={true}
                    initialData={initialData}
                    api={{
                        getItems: getTimesheets,
                        createItem: createTimesheet,
                        updateItem: updateTimesheet,
                        deleteItem: deleteTimesheet,
                    }}
                    parentId={task.id!}
                />
            </DialogContent >
        </Dialog>
    )
}

export default TimesheetDialog