import { Box, IconButton, TextField } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
export default function StockQtyInput({ row }) {
  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        hiddenLabel
        defaultValue={row.original.stockQty}
        variant="standard"
        size="small"
      />
      <IconButton aria-label="edit" size="small">
        <EditIcon color="success" />
      </IconButton>
    </Box>
  );
}
