import { TextField } from '@mui/material';
import React from 'react';
import VendorSelector from '../../../../BidTab/components/vendorSelector';
import LabelledBox from '../../../../../../common/components/LabelledBox';

export default function AddNewEntity({
  currentAction,
  actionTextData,
  onTextChange,
}) {
  return (
    <>
      <TextField
        id="number"
        label={currentAction + ' Number'}
        name="number"
        value={actionTextData.number}
        onChange={onTextChange}
        variant="outlined"
        fullWidth
        autoComplete="off"
      />
      <TextField
        margin="dense"
        name="date"
        value={actionTextData.date}
        onChange={onTextChange}
        type="date"
        variant="outlined"
        fullWidth
      />
      {currentAction === 'PO' && (
        <LabelledBox label="Vendors">
          <VendorSelector isMultiple={false} />
        </LabelledBox>
      )}
    </>
  );
}
