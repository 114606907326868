import { Alert, Box, Button } from '@mui/material';
import React from 'react';
import MrqSelector from '../MRQTab/components/MrqSelector';
import BidTable from './BidTable';
import VendorSelector from './components/vendorSelector';
import { MCSContext } from '../MCS.context';
import { postBidSelection } from './api';
import { BidChatContextProvider } from './BidChat.context';
import LabelledBox from '../../../common/components/LabelledBox';
import BidChat from '../../../common/components/Chat/BidChat';

const BidTab = () => {
  const { mrqSelection } = React.useContext(MCSContext);

  const totalCost = 0;

  return (
    <BidChatContextProvider>
      <Box>
        <Box
          display={'flex'}
          gap={1}
          sx={{ alignItems: 'baseline', height: 'max-content' }}
        >
          <LabelledBox label="MRQ Manager">
            <MrqSelector />
          </LabelledBox>
          {mrqSelection.selectedMrq ? (
            <LabelledBox label="Vendors">
              <VendorSelector isMultiple={true} />
            </LabelledBox>
          ) : (
            <></>
          )}
        </Box>
        <LabelledBox label="Bid Table">
          <BidTable totalCost={totalCost} />
          <Box sx={{ p: 2, pb: 0 }}>
            <BidTableButton />
          </Box>
        </LabelledBox>

        {/* ------------ Chat box ! ------------------ */}
        <BidChat />
      </Box>
    </BidChatContextProvider>
  );
};

export default BidTab;

const BidTableButton = () => {
  const { BidTableRows, setBidTableRows, mrqData } =
    React.useContext(MCSContext);

  const [alert, setAlert] = React.useState('');

  const isBidTableButtonDisabled = () => {
    let disabled = true;

    BidTableRows.forEach((row) => {
      if (row.po) disabled = false;
    });

    return disabled;
  };

  const onReset = () => {
    setBidTableRows(
      BidTableRows.map((r) => {
        return { ...r, po: '', selected: {}, vendorId: '' };
      }),
    );
  };

  const onSubmit = async () => {
    const data = await postBidSelection({
      mrqId: mrqData.id,
      bidsSelection: BidTableRows,
    });
    if (data && data[0] && data[0].id) {
      setAlert('success');
    } else {
      setAlert('warning');
    }
  };

  return (
    <>
      {alert ? (
        <Alert
          severity={alert}
          sx={{ width: '100%' }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                setAlert('');
              }}
            >
              Edit
            </Button>
          }
        >
          Thanks, Changes has been saved.
        </Alert>
      ) : (
        <>
          <Button
            disabled={isBidTableButtonDisabled()}
            variant="contained"
            sx={{ float: 'right' }}
            onClick={onSubmit}
          >
            Submit Bids Selection
          </Button>

          <Button
            disabled={isBidTableButtonDisabled()}
            sx={{ float: 'right', mr: 2 }}
            onClick={onReset}
          >
            Reset All
          </Button>
        </>
      )}
    </>
  );
};
