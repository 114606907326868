import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";

export default function ChatBody({ msgs }) {
  return (
    <Box sx={{ height: "100%", overflowY: "auto", pb: 22 }}>
      {msgs?.map((m) => {
        return (
          <Box sx={{ clear: "both" }} key={m.id}>
            <Paper
              sx={{
                maxWidth: "70%",
                border: "1px solid #eee",
                p: 1,
                m: 1.5,
                float: m.from === "me" ? "right" : null,
                backgroundColor:
                  m.from === "me" ? "hsla(250, 86%, 97%, 1)" : null,
              }}
            >
              <span style={{ margin: "5px" }}>{m.body}</span>
              <Box sx={{ float: "right", color: "#777", fontSize: "12px" }}>
                {m.createdAt}
                {m.from === "me"
                  ? m.seen === true
                    ? " - seen by email"
                    : " - not seen yet"
                  : ""}
              </Box>
            </Paper>
          </Box>
        );
      })}
      <ChatInput />
    </Box>
  );
}

function ChatInput() {
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "97%",
        zIndex: 4,
        backgroundColor: "white",
      }}
    >
      <FormControl variant="outlined" sx={{ width: "80%" }}>
        <OutlinedInput
          id="outlined-adornment-weight"
          multiline
          maxRows={4}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="send">
                <SendIcon />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
      </FormControl>
    </Box>
  );
}
