import { Typography } from "@mui/material"

export const timesheetColumns =  [
    {
        accessorKey: "id",
        header: "key",
        size: 50,
        enableEditing: false,
    },
    {
        accessorKey: "date",
        header: `Date`,
        size: 60,
        muiEditTextFieldProps: {
            type: "date",
        },
        enableEditing: false,
        Footer: () => (
            <Typography color="warning.main" sx={{ pl: 2, fontWeight: "bold" }}>Total</Typography>
        )

    },
    {
        accessorKey: "workDone",
        header: `Work Done`,
        enableEditing: false,
        size: 300,
    },
    {
        accessorKey: "task.task",
        header: `Task`,
        enableEditing: false,
        size: 120,
    },
    {
        accessorKey: "task.project.name",
        header: `Project`,
        enableEditing: false,
        size: 100,
    },
    {
        accessorKey: "hours",
        header: `Hours`,
        size: 60,
        muiEditTextFieldProps: {
            type: "number",
        },
        enableEditing:false,
        Footer: ({ table }: any) => {
            const totalHours = table.getRowModel().rows.reduce((total: number, row: any) => {
                return total + parseFloat(row.original.hours || 0);
            }, 0);

            return (
                <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>{totalHours}</Typography>
                // <Typography>Hours: {totalHours}</Typography>
            );
        }
    },
    {
        accessorKey: "progress",
        header: `Progress`,
        size: 60,
        enableEditing: false,
        muiEditTextFieldProps: {
            type: "number",
        },
        Footer: ({ table }: any) => {
            const totalProgress = table.getRowModel().rows.reduce((total: number, row: any) => {
                return total + parseFloat(row.original.progress || 0);
            }, 0);

            return (
                <Typography color="warning.main" sx={{ pl: 1, fontWeight: "bold" }}>{totalProgress}%</Typography>
                // <Typography>Progress: {totalProgress}%</Typography>
            );
        }
    },
    {
        accessorKey: "user",
        header: `By`,
        size: 60,
        enableEditing: false
    },
]