import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { MaterialReactTable } from 'material-react-table';
import { SPIRGrid } from './utils';
import { SPIRColumns } from './utils';
import { labelledBoxStyles, lightTable } from '../../../common/styles/muiProps';
import { BootstrapInput } from '../../../common/components/BootstrapInput';

const SPIRTab = () => {
  return (
    <div>
      <Box sx={{ ...labelledBoxStyles }}>
        <Box display="flex" alignItems="center" style={{ gap: '16px' }}>
          <Typography>SPIR No.</Typography>
          <Select
            labelId="spir-label"
            value="SPIR-001"
            // onChange={handleChange}
            // label="Document Transmittal"
            input={<BootstrapInput />}
          >
            <MenuItem key={0} value={'SPIR-001'}>
              SPIR-001
            </MenuItem>
          </Select>

          <Button variant="contained" startIcon={<AddIcon />}>
            Add New
          </Button>
        </Box>
      </Box>

      {/* <br />
            <br /> */}
      <Box sx={{ ...labelledBoxStyles }}>
        <br />
        <Box>
          <Grid
            container
            spacing={2}
            columns={{ xs: 6.8 }}
            sx={{ width: '900px' }}
          >
            {SPIRGrid.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={item.grid}>
                  <Typography>{item.label}</Typography>
                  {/* <Box sx={{ backgroundColor: "red" }}>{item.label}</Box> */}
                </Grid>
                <Grid
                  item
                  xs={
                    item.field === 'documentTransmittalNumber' ||
                    item.field === 'to'
                      ? 2.3
                      : 2
                  }
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    hiddenLabel
                    size="small"
                    disabled
                    fullWidth
                    inputProps={{ sx: { padding: '2px', pl: '5px' } }}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Box>
        <br />
        <MaterialReactTable columns={SPIRColumns} data={[]} {...lightTable} />
      </Box>
    </div>
  );
};

export default SPIRTab;
