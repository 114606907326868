import React from 'react'
import RACITab from '../ProjectsPage/SingleProjectPage/tabs/RACI'
import { getAllRACI } from './api'
import HeadingComponent from '../../common/components/HeadingComponent'
import { Box } from '@mui/material'

const RaciPage = () => {
  return (
    <Box padding={"2rem"}>
      <HeadingComponent heading='RACI' />
      <RACITab getItems={getAllRACI} />
    </Box>
  )
}

export default RaciPage