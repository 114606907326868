import React, { useEffect, useState } from 'react'
import { EditableTable } from '../../../../../common/components/table/EditableTable'
import Task from './taskInterface'
import { createTask, deleteTask, getTasks, updateTask } from './api'
import { initialDialogState, taskListColumns } from './utils'
import CommentsDialog from './components/CommentsDialog.tsx'
import TimesheetDialog from './components/TimesheetDialog.tsx'
import { useParams } from 'react-router-dom'
import User from '../../../../UsersPage/interface/userInterface'
import { getUsers } from '../../../../UsersPage/api'
import Project from '../../../interfaces/projectInterface'
import { getProject } from '../../../api'


const initialData: Task = {
    id: "",
    task: "",
    priority: "",
    startDate: "",
    endDate: "",
    status: "",
    hours: 0,
    progress: 0,
    users: [],

}


interface PunchlistProps {
    project?: Project | undefined
}

const TasklistTab = ({ project }: PunchlistProps) => {

    const project_members = ["John Doe", "John SMith"]
    const projectLeads = ["John Doe", "John SMith"]
    const currentUser = "currentUser"


    const { projectId } = useParams()
    const [dialogState, setDialogState] = React.useState(initialDialogState)
    const [selectedTask, setSelectedTask] = React.useState()
    const [ projectData, setProjectData] = React.useState<Project>();
    console.log("project users", projectData?.users)

    React.useEffect(() => {
        const fetchProjectData = async () => {
            await getProject(projectId).then((res) => setProjectData(res));
        };
        fetchProjectData();
    }, []);

    const handleButtonClick = (type: string, row: any) => {
        setSelectedTask(row.original)

        if (type === 'comments') {
            setDialogState(prev => ({ ...prev, comments: true }))
        }
        else if (type === 'attachment') {
            setDialogState(prev => ({ ...prev, attachment: true }))
        }
        else if (type === 'timesheet') {
            setDialogState(prev => ({ ...prev, timesheet: true }))
        }
    }

    const handleDialogClose = () => {
        setDialogState(initialDialogState)
    }

    return (
        <div>
            <EditableTable<Task>
                name="Task"
                displayMode="row"
                columns={taskListColumns({
                    project_members: projectData?.users,
                    handleButtonClick: handleButtonClick,
                    currentUser,
                })}
                enableEditing={true}
                enableCreate={true}
                initialData={initialData}
                api={{
                    getItems: getTasks,
                    createItem: createTask,
                    updateItem: updateTask,
                    deleteItem: deleteTask,
                }}
                parentId={projectId!}

            />
            {
                selectedTask ?
                    <>
                        <CommentsDialog open={dialogState.comments} handleClose={handleDialogClose} task={selectedTask} />
                        <TimesheetDialog open={dialogState.timesheet} handleClose={handleDialogClose} task={selectedTask} />
                    </>
                    :
                    <>
                    </>
            }
        </div>
    )
}

export default TasklistTab