import { RouteObject } from 'react-router-dom';

import TasksPage from '.';
// import { getProjectsList } from './api';

export const paths = {
    mytasks: "/tasks"
}

export const taskRoutes: RouteObject[] = [
    {
        path: paths.mytasks,
        element: <TasksPage />,
    },
];
