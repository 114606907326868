import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface LabelledBoxProps {
  label: string;
  children: ReactNode; // Allow any ReactNode as children
  sx?: any;
}

const LabelledBox: React.FC<LabelledBoxProps> = ({ label, children, sx }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#f3f4f6',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        pt: 1,
        mb: 2,
        width: 'max-content',
        ...sx,

        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
      }}
    >
      <Typography sx={{ fontWeight: 'bold', color: '#565D6DFF', pb: 1 }}>
        {label}
      </Typography>
      <div>{children}</div>
    </Box>
  );
};

export default LabelledBox;
