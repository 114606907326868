import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  Button,
} from '@mui/material';
import { getDrawings } from '../api';
import { useParams } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { BootstrapInput } from '../../../../common/components/BootstrapInput';
import { MCSContext } from '../../MCS.context';

const initialState = {
  name: '',
  revision: '',
  sheet: '',
  title: '',
};
const DrawingSelection = ({ selectedDrawings, handleAddingNewDrawing }) => {
  const { project } = React.useContext(MCSContext);

  const [data, setData] = useState([]);
  const [selectedDrawing, setSelectedDrawing] = useState(initialState);

  const [revisions, setRevisions] = useState([]);
  const [sheets, setSheets] = useState([]);

  const [selectionResult, setSelectionResult] = useState({});

  useEffect(() => {
    // Fetch data from the API
    const fetchDrawings = async () => {
      await getDrawings(project.id)
        .then((response) => {
          setData(response);
          console.log({ projectId: project.id, fetchDrawings: response });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };
    fetchDrawings();
  }, []);

  const onDrawingChange = (drawing) => {
    setSelectionResult(drawing);
  };

  const handleNameChange = (event) => {
    const name = event.target.value;
    setSelectedDrawing({ ...initialState, name });

    const revs = [
      ...new Set(
        data
          .filter((d) => d.name === name && d.revision !== '')
          .map((d) => d.revision),
      ),
    ];
    setRevisions(revs);
    setSheets([]);

    // if no revisions, fetch drawing
    if (!revs.length) {
      const selectedDrawing = data.find((d) => d.name === name);
      onDrawingChange(selectedDrawing);
    }
  };

  const handleRevisionChange = (event) => {
    const revision = event.target.value;

    setSelectedDrawing({ ...selectedDrawing, revision, sheet: '' });

    const sheetList = [
      ...new Set(
        data
          .filter(
            (d) =>
              d.name === selectedDrawing.name &&
              d.revision === revision &&
              d.sheetNumber !== '',
          )
          .map((d) => d.sheetNumber),
      ),
    ];
    setSheets(sheetList);

    // if no sheets, fetch drawing
    if (!sheetList.length) {
      const selectedDrawing = data.find(
        (d) => d.name === selectedDrawing.name && d.revision === revision,
      );
      onDrawingChange(selectedDrawing);
    }
  };

  const handleSheetChange = (event) => {
    const sheetNumber = event.target.value;

    setSelectedDrawing({ ...selectedDrawing, sheet: sheetNumber });
    const selectedDrawingData = data.find(
      (d) =>
        d.name === selectedDrawing.name &&
        d.revision === selectedDrawing.revision &&
        d.sheetNumber === sheetNumber,
    );
    console.log({ data, selectedDrawingData });
    onDrawingChange(selectedDrawingData);
  };

  const onAddSelectedDrawing = async () => {
    if (!selectionResult.id) {
      alert('Please select a specific drawing to be added.');
    } else {
      const isSelectedBefore = selectedDrawings.find(
        (d) => d.id === selectionResult,
      );

      if (!isSelectedBefore?.id) {
        handleAddingNewDrawing({ drawing: selectionResult });
      } else {
        alert('This drawing is already selected !');
      }
    }
  };

  return (
    <Stack direction={'row'}>
      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: '1.1rem' }}>
          Drawing/Doc #
        </InputLabel>
        <Select
          value={selectedDrawing.name}
          onChange={handleNameChange}
          input={<BootstrapInput />}
        >
          {Array.from(new Set(data?.map((d) => d.name))).map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
        disabled={!selectedDrawing.name}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: '1.1rem' }}>
          Revision
        </InputLabel>
        <Select
          value={selectedDrawing.revision}
          onChange={handleRevisionChange}
          input={<BootstrapInput />}
        >
          {revisions?.map((rev) => (
            <MenuItem key={rev} value={rev}>
              {rev}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
        disabled={!selectedDrawing.revision}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: '1.1rem' }}>
          Sheet
        </InputLabel>
        <Select
          value={selectedDrawing.sheet}
          onChange={handleSheetChange}
          input={<BootstrapInput />}
        >
          {sheets?.map((sheet) => (
            <MenuItem key={sheet} value={sheet}>
              {sheet}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
        disabled={!selectedDrawing.revision}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: '1.1rem' }}>
          Title
        </InputLabel>
        <BootstrapInput disabled value={selectionResult?.title} />
      </FormControl>

      <Button
        variant="contained"
        sx={{ mt: 3, ml: 3, mb: 2 }}
        startIcon={<AddCircleOutlineIcon />}
        onClick={onAddSelectedDrawing}
      >
        Add
      </Button>
    </Stack>
  );
};

export default DrawingSelection;
