// import project from './interfaces/projectInterface';
// import axois from '../../common/api/axois';
// import axios from 'axios';

import axios from "../../common/api/axios";
import Project from "./interfaces/projectInterface";

export const getProjectsList = async () => {
  // const projectData = await axios.get('/projects')

  return [
    {
      id: '1',
      name: 'test1',
      people: ['ahmed', 'ali'],
      assymblies: ['as1', 'as2'],
      progress: 75,
    },
    {
      id: '2',
      name: 'test2',
      people: ['ahmed', 'ali'],
      assymblies: ['as1', 'as2'],
      progress: 75,
    },
    {
      id: '3',
      name: 'test3',
      people: ['ahmed', 'ali'],
      assymblies: ['as1', 'as2'],
      progress: 75,
    },
  ];
};


export const getProject = async (projectId: string | undefined): Promise<Project> => {
  const response = await axios.get(`/project/${projectId}`);
  return response.data;
};
export const getProjects = async (): Promise<Project[]> => {
  const response = await axios.get('/project');
  return response.data;
};

export const createProject = async (parentId: string, project: Project): Promise<void> => {
  console.log("creating", { project })
  const { progress, id, ...reducedProject } = project
  await axios.post('/project', reducedProject);
};

export const updateProject = async (project: Project): Promise<void> => {
  const { id, progress, ...reducedProject } = project

  await axios.patch(`/project/${project.id}`, reducedProject);

  const reshapeProjectUsers = (p: Project) => {
    const users = p.users.map((u) => {
      return {
        id: u?.id,
        userId: u.userId,
        role: u.role,
        projectId: project.id
      };
    });
    return users;
  }
  await axios.patch(`/project/update-user-role/${project.id}`, reshapeProjectUsers(project))
};

export const deleteProject = async (projectId: string): Promise<void> => {
  await axios.delete(`/project/${projectId}`);
};
