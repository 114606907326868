import { Box } from '@mui/material';
import React from 'react';
import MrqSelector from './components/MrqSelector';
import MrqDetails from './components/MrqDetails';
import MrqMaterialsTable from './components/MrqMaterialsTable/index';
import { MCSContext } from '../MCS.context';
import { getMrqs } from '../api';
import LabelledBox from '../../../common/components/LabelledBox';
import { useParams } from 'react-router-dom';

const MRQTab = () => {
  const { mrqData, setMrqList } = React.useContext(MCSContext);

  const { projectId } = useParams();
  React.useEffect(() => {
    const fetchMrqList = async () => {
      await getMrqs({ projectId }).then((res) => setMrqList(res));
    };

    fetchMrqList();
  }, []);

  console.log('mrqTab :', mrqData);

  return (
    <Box>
      <LabelledBox label="MRQ Manager">
        <MrqSelector />
      </LabelledBox>

      <MrqDetails />

      <MrqMaterialsTable
        data={mrqData?.mrqMaterials || []}
        pos={mrqData?.pos || []}
      />

      {/* <AddDataDialog open={dialog.open} onClose={onClose} /> */}
    </Box>
  );
};

export default MRQTab;
