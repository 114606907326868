import { Dialog, DialogContent, Typography } from '@mui/material';
import AutodeskViewer from './AutodeskViewer';

interface ViewerProps {
  open: boolean;
  handleClose: (event: MouseEvent) => void;
  urn: string | undefined;
}

const Viewer = ({ open, handleClose, urn }: ViewerProps) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
      <DialogContent>
        <AutodeskViewer prepUrn={urn} />
      </DialogContent>
    </Dialog>
  );
};

export default Viewer;
