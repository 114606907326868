import React, { createContext } from "react";
import { MCSContext } from "../MCS.context";

const BidChatContext = createContext({
  vendors: [],
  setVendors: () => {},

  openChatVendorIndex: [],
  setOpenChatVendorIndex: () => {},
});

function BidChatContextProvider({ children }) {
  const [openChatVendorIndex, setOpenChatVendorIndex] = React.useState("");
  const { mrqData } = React.useContext(MCSContext);

  const vendors = mrqData.vendors;
  return (
    <BidChatContext.Provider
      value={{
        vendors: vendors || [],
        openChatVendorIndex,
        setOpenChatVendorIndex,
      }}
    >
      {children}
    </BidChatContext.Provider>
  );
}

export { BidChatContextProvider, BidChatContext };
