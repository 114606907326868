import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
// import sp_icon from '../../../../../images/sp_icon.png';
import sp_icon from '../../../../../common/assets/images/sp_icon.png';
// import axios from '../../../../../api/axios';
import axios from '../../../../../common/api/axios';

const SharepointTab = (props) => {
  // const { subAssemblyRoute } = props
  const [generatedLink, setGeneratedLink] = useState('');
  const temporaryLink =
    'https://enerfacprojectscom.sharepoint.com/sites/StorageTank/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FStorageTank%2FShared%20Documents%2FEP%20%2D%20ST%20%2D%20001&viewid=85f025a8%2D20d0%2D4845%2D9975%2D3e77c36cfbf9';
  const subAssemblyRoute = `/generateLink/EP - ST - 01/tank/Tank 1/:rev`;
  console.log('DCSSSS');
  useEffect(() => {
    // Make an API request to the backend to get the link -
    // TODO: just send EquipId here..
    axios
      .get('pressure_vessel/generateLink/' + subAssemblyRoute)
      .then((response) => {
        console.log(response);
        setGeneratedLink(response.data.link);
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  const handleClick = () => {
    // Open the link in a new tab
    window.open(temporaryLink, '_blank');
  };

  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      display="flex"
      minHeight="60vh"
    >
      {/* <a href="http://localhost:8000/pv/download">Download Installer</a> */}
      <Card
        sx={{
          width: 370,
          '& .MuiCardMedia-root': { objectFit: 'contain' },
          p: 2,
        }}
      >
        <CardMedia
          component="img"
          sx={{ height: 140 }}
          image={sp_icon}
          title="sp icon"
        />
        <CardContent>
          <Typography sx={{ fontSize: 18 }} gutterBottom>
            Access Your Sharepoint Documents here.
          </Typography>
          <br />
        </CardContent>
        <CardActions disableSpacing>
          <Button
            sx={{ backgroundColor: '#1c9ca4', color: '#fff', ml: 2 }}
            variant="contained"
            endIcon={<LaunchIcon />}
            onClick={handleClick}
          >
            Go to Sharepoint
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default SharepointTab;
